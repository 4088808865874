import React from 'react';
import {connectBreadcrumb} from 'react-instantsearch-dom';

const Breadcrumb = ({items, refine, createURL}) =>
    Boolean(items.length) && (
        <>
            {items.length === 1 ?
                <div className="return-icon-category">
                    <a
                        href={createURL()}
                        onClick={event => {
                            event.preventDefault();
                            refine();
                        }}
                    >
                        cofnij do Wszystkie kategorie
                    </a>
                </div>

                :
                items.map((item, i) => {
                    return (
                        <div key={item.value}
                             style={{display: items.length - 1 === i ? 'none' : 'block'}}
                             className="return-icon-category"
                        >
                            <a
                                href={createURL(item.value)}
                                onClick={event => {
                                    event.preventDefault();
                                    refine(item.value);
                                }}
                            >
                                cofnij do {item.label}
                            </a>
                        </div>
                    )
                })
            }

        </>
    );

const BreadcrumbCustom = connectBreadcrumb(Breadcrumb);

export default BreadcrumbCustom;