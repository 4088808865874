import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Button, Col, Row, Space, Spin, Tabs} from "antd";

import {Context} from "../../index";
import {fetchProducts} from "../../http/productAdAPI";
import {fetchHomePage} from "../../http/pages/homeAPI";
import HeroSlider from "../../components/pages/home/HeroSlider";
import CategorySlider from "../../components/pages/home/CategorySlider";
import CardCustom from "../../components/Card/CardCustom";
import {ACCOUNT_ROUTE, ACCOUNT_ROUTE_ADD_PRODUCT} from "../../utils/consts";

import './style.less'
import {Helmet} from "react-helmet";


const Home = observer(() => {
    const {product, global, user} = useContext(Context)
    const [homePage, setHomePage] = useState([])

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        fetchProducts().then(data => product.setProductsPromoHome(data))
        fetchHomePage().then(data => data.attributes ? setHomePage(data.attributes) : '')
            .finally(() => setLoading(false))
    }, [product])

    if (loading) {
        return <Spin animation={"grow"}/>
    }

    return (
        <div className="home-page-wrapper">
            <Helmet>
                <title>Obrabiarka.pl – obrabiarki numeryczne CNC, giełda maszyn CNC</title>
                <meta
                    name="description"
                    content="Giełda maszyn CNC. U Nas kupisz jak i sprzedasz maszyny i narzędzia
                            CNC. Zapraszamy do zapoznania się z naszą ofertą obrabiarek CNC i akcesoriów."
                />
            </Helmet>
            <HeroSlider heroMobileSlug={homePage.heroMobileSlug} heroMobile={homePage.heroMobile}/>
            <Row gutter={[16, 16]} className="category-slider margin-lr">
                <Col span={24}>
                    <CategorySlider cat={global.categories}/>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="margin-lr">
                <Col span={24}>
                    <h2 className="title-main">Ogłoszenia promowane</h2>
                </Col>
                {product.productsPromoHome.length ?
                    product.productsPromoHome.map((item, key) => {
                            if (item.attributes.isDisplayHome && new Date(item.attributes.endPromo) > new Date()) {
                                return (
                                    <Col lg={6} md={8} sm={12} span={12} key={key}>
                                        <CardCustom item={item}/>
                                    </Col>
                                )
                            }
                        }
                    )
                    :
                    <div>Nie ma żadnego..</div>
                }
            </Row>
            <Row
                gutter={[16, 16]}
                className="sell-section margin-lr"
                justify="center"
                align="center"
            >
                <Col span={24} md={13} className="label-info">
                    <div className="info">
                        <div className="label">
                            <p>{homePage.label}</p>
                        </div>
                        <div className="title-sell">
                            <p>{homePage.title}</p>
                        </div>
                    </div>
                </Col>
                <Col span={24} md={5} className="button-add-ad-wrapper">
                    <Space className='btn-wrapper-right thx-btn'>
                        <Link to={user.isAuth ? ACCOUNT_ROUTE_ADD_PRODUCT : ACCOUNT_ROUTE}>
                            <Button
                                type="primary"
                            >
                                Dodaj ogłoszenie
                            </Button>
                        </Link>
                    </Space>
                </Col>
            </Row>
            <Row
                gutter={[16, 16]}
                className="tabs-home margin-lr"
                justify="center"
            >
                <Col span={24} lg={18}>
                    <Tabs destroyInactiveTabPane={true} hideAdd={true}>
                        <Tabs.TabPane tab={homePage.TabPanelLeft} key="1">
                            <div className="tab-title-wrapper">
                                <h3>{homePage.TabTitleLeft}</h3>
                            </div>
                            <Row
                                gutter={[16, 16]}
                                className="list-wrapper-custom list-wrapper-custom-left margin-lr"
                                justify="center"
                            >
                                {homePage.sellList.map((el) => {
                                    return <Col span={24} md={12} lg={8} key={el.id}>
                                        <div className="card-wrapper">
                                            <div className="image-steps"/>
                                            <p>{el.label}</p>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={homePage.TabPanelRight} key="2">
                            <div className="tab-title-wrapper">
                                <h3>{homePage.TabTitleRight}</h3>
                            </div>
                            <Row
                                gutter={[16, 16]}
                                className="list-wrapper-custom list-wrapper-custom-right margin-lr"
                                justify="center"
                            >
                                {homePage.buyList.map((el) => {
                                    return <Col span={24} md={12} lg={8} key={el.id}>
                                        <div className="card-wrapper">
                                            <div className="image-steps"/>
                                            <p>{el.label}</p>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
            <Row
                gutter={[16, 16]}
                className="newsletter margin-lr"
                justify="center"
            >
                <Col span={24}>
                    <div
                        className="newsletter-wrapper"
                        dangerouslySetInnerHTML={{__html: homePage.newsletter}}/>
                </Col>
            </Row>
            <Row
                gutter={[16, 16]}
                className="about-us margin-lr"
                justify="center"
            >
                <Col span={22} lg={18}>
                    <div className="about-us-logo">
                        <img
                            src={homePage.logo.data.attributes.url}
                            alt={homePage.logo.data.attributes.alternativeText}/>
                    </div>
                    <div
                        className="about-us-description"
                        dangerouslySetInnerHTML={{__html: homePage.aboutUs}}/>
                </Col>
            </Row>
        </div>
    );
});

export default Home;