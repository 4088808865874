import React, {useEffect, useReducer, useState} from 'react';
import sha384 from 'crypto-js/sha384';
import {Button, Radio, Col, Row, Space, Checkbox, Modal, Form, Select, Input, message, Switch} from "antd";

import {updateUserData} from "../../http/userAPI";
import {fetchPaymentsCard, registerTransaction} from "../../http/przelewy24API";
import {REDIRECT_PAYMENT_ROUTE} from "../../utils/consts";
import {Helmet} from "react-helmet";


const BuyPackages = ({user, sessionID, voivodeships}) => {
    const [disclaimer, setDisclaimer] = useState(false)
    const [open, setOpen] = useState(false);
    const {Option} = Select;
    const [form] = Form.useForm()
    const [expand, setExpand] = useState(false)
    const [paymentsCard, setPaymentsCard] = useState([])
    const [paymentData, setPaymentData] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            merchantId: parseInt(process.env.REACT_APP_PRZELEWY24_MERCHANT_ID),
            posId: parseInt(process.env.REACT_APP_PRZELEWY24_POS_ID),
            sessionId: sessionID,
            amount: 0,
            currency: "PLN",
            description: "",
            email: user.user.email,
            client: `${user.user.fullName}, ${user.user.companyName}, ${user.user.nip}`,
            address: `${user.user.city}, ${user.user.street}, ${user.user.postalCode}`,
            country: "PL",
            language: "pl",
            method: 0,
            urlReturn: `${process.env.REACT_APP_FRONT_URL}/konto/status`,
            sign: ""
        })


    useEffect(() => {
        fetchPaymentsCard().then(data => setPaymentsCard(data))
        localStorage.removeItem('order')
    }, [])


    const isValidation = (!user.user.companyName || !user.user.nip || !user.user.voivodeship || !user.user.city
            || !user.user.street || !user.user.postalCode)
        && (!expand || !user.user.voivodeship || !user.user.city || !user.user.street || !user.user.postalCode)

    console.log('paymentData',paymentData)
    const click = async () => {
        let id = user.user.id;
        if (isValidation) {
            setOpen(true)
        } else {
            try {
                await registerTransaction(paymentData).then((res) => {
                    user.setUser({...user.user, isPay: false})
                    updateUserData(id, user.user)
                    window.location.href = REDIRECT_PAYMENT_ROUTE + res.token
                })
            } catch (e) {
                console.log("err", e.response.data.error)
            }
        }
    }
    const handleChange = (e) => {
        const {name, value} = e.target
        user.setUser({...user.user, [name]: value})
    }

    const handleInvoiceChange = (e) => {
        const tempLocalDataInvoice = JSON.parse(localStorage.getItem('order'))
        localStorage.setItem('order', JSON.stringify({
            ...tempLocalDataInvoice,
            invoice: e.target.checked
        }))
    }
    const handleDisclaimerChange = (e) => {
        setDisclaimer(e.target.checked)
    }
    const handlePriceChange = (e) => {
        const tempLocalDataOrder = JSON.parse(localStorage.getItem('order'))
        localStorage.setItem('order', JSON.stringify({
            ...tempLocalDataOrder,
            sessionID: paymentData.sessionId,
            amountUser: e.target.dataPrice,
            payment: e.target.dataOrder,
        }))

        const dataSHA = sha384(JSON.stringify({
            "sessionId": paymentData.sessionId,
            "merchantId": parseInt(process.env.REACT_APP_PRZELEWY24_MERCHANT_ID),
            "amount": e.target.dataPrice * 100,
            "currency": paymentData.currency,
            "crc": process.env.REACT_APP_PRZELEWY24_CRC
        }))

        setPaymentData({
            amount: e.target.dataPrice * 100,
            sign: dataSHA.toString(),
            description: `Pakiet ${parseInt(e.target.value)}.`
        })
    }



    const handleOk = async () => {
        let id = user.user.id;
        try {
            await updateUserData(id, user.user)
            message.success("Dane zaktualizowane")
            setOpen(false)
        } catch (e) {
            console.log("err", e.response.data.error)
        }
    };
    const handleCancel = () => {
        setOpen(false)
    };

    return (
        <div className="page-wrapper buy-packages-wrapper">
            <Helmet>
                <title>Doładuj saldo Konta</title>
            </Helmet>

            <Modal
                title="Uzupełnij dane adresowe do faktury"
                open={open}
                onOk={handleOk}
                okText="Zapisz"
                cancelText="Anuluj"
                onCancel={handleCancel}
                className="modal-custom-form"
            >
                <Form
                    form={form}
                    initialValues={user.user}
                    name=""
                    layout="vertical"
                    onFinish={click}
                    autoComplete="off"
                    style={{ marginBottom: "0px" }}
                >
                    <Col span={24} lg={24}>
                        <Form.Item name="voivodeship" label="Województwo">
                            <Select placeholder="Wybierz województwo"
                                    name="voivodeship"
                                    onChange={(_, el) => {
                                        user.setUser({...user.user, voivodeship: el.children})
                                    }}
                            >
                                {voivodeships.map((el) =>
                                    <Option key={el.id} value={el.attributes.slug}>{el.attributes.label}</Option>
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item name="city" label="Miejscowość">
                            <Input maxLength={40} name="city" onChange={e => handleChange(e)}/>
                        </Form.Item>
                        <Form.Item name="street" label="Ulica / nr domu">
                            <Input maxLength={60} name="street" onChange={e => handleChange(e)}/>
                        </Form.Item>
                        <Form.Item name="postalCode" label="Kod pocztowy">
                            <Input maxLength={6} name="postalCode" onChange={e => handleChange(e)}/>
                        </Form.Item>
                        <div className='custom-item-wrapper'>
                            <span>Jestem osoba prywatną</span>
                            <Form.Item>
                                <Switch onChange={e => setExpand(!expand)}/>
                            </Form.Item>
                        </div>
                        {!expand ?
                            <>
                                <Form.Item name="companyName" label="Nazwa firmy">
                                    <Input maxLength={40} name="companyName" onChange={e => handleChange(e)}/>
                                </Form.Item>
                                <Form.Item name="nip" label="NIP">
                                    <Input maxLength={10} name="nip" onChange={e => handleChange(e)}/>
                                </Form.Item>
                            </>
                            :
                            ''
                        }


                    </Col>
                </Form>
            </Modal>

            <Row justify="center">
                <Col span={24}>
                    <div className="payment-promo-info">
                        <p>Kwotę doładowania możesz wykorzystać dowolnie - na wystawienie ogłoszeń i/lub opcję
                            promowania ogłoszenia na stronie głównej:</p>
                    </div>
                    <Radio.Group buttonStyle="solid" className="buy-cards">
                        {paymentsCard.map((el) => {
                            return (
                                <Radio.Button
                                    className="buy-card"
                                    key={el.id}
                                    onChange={handlePriceChange}
                                    value={el.attributes.amount}
                                    dataPrice={el.attributes.price}
                                    dataOrder={el.id}>
                                    <div className="card-text">
                                        <p className="card-title">
                                            {el.attributes.title}
                                        </p>
                                        <div className="price-container">
                                            <p className="price-text">
                                                <span className="top-label">{el.attributes.textUnderPrice}</span>
                                            </p>
                                            <p className="main-price">
                                                <strong>{el.attributes.price} zł</strong>
                                            </p>
                                            <div
                                                className="price-text price-text-above"
                                                dangerouslySetInnerHTML={{__html: el.attributes.textAbovePrice}}/>
                                        </div>
                                        <p className="card-info">
                                            {el.attributes.description}
                                        </p>
                                    </div>
                                </Radio.Button>
                            )
                        })}
                    </Radio.Group>
                </Col>
                <Col span={24}>
                    <div className='custom-item-wrapper'>
                        <Space>
                            <Checkbox
                                name="invoice"
                                onChange={handleInvoiceChange}
                            >
                                Chcę otrzymać fakturę
                            </Checkbox>
                        </Space>
                        <Space>
                            <Checkbox
                                name="disclaimer"
                                onChange={handleDisclaimerChange}
                            >
                                * Upoważniam firmę Abplanalp Sp. z o.o. do natychmiastowej aktywacji zakupionej przeze
                                mnie usługi. Tym samym przyjmuję do wiadomości, że utracę 14-dniowe prawo do odstąpienia
                                od umowy z chwilą jej pełnego wykonania.
                            </Checkbox>
                        </Space>
                    </div>
                </Col>
                <Col span={24}>
                    <Space className='btn-wrapper-center thx-btn'>
                        <Button
                            className="ant-btn-primary"
                            name="amount"
                            disabled={paymentData.amount === 0 || !disclaimer}
                            onClick={click}
                        >
                            Kup pakiet ({paymentData.amount / 100} zł)
                        </Button>
                    </Space>
                </Col>

            </Row>
        </div>
    );
};

export default BuyPackages;