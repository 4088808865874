import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";
import {Affix, Button, Col, Row, Space, Spin, Typography} from "antd";

import {fetchBlogList} from "../../http/pages/blogAPI";

import './style.less'
import dateIcon from '../../assets/icons/date.svg'


const Blog = () => {
    const [htmlBlogList, setHTMLBlogList] = useState({})
    const [loading, setLoading] = useState(true)
    const {Paragraph} = Typography;

    useEffect(() => {
        fetchBlogList().then(data => setHTMLBlogList(data))
            .finally(() => setLoading(false))
    }, [])

    if (loading) {
        return <Spin animation={"grow"}/>
    }
    return (
        <div className="page-wrapper blog-wrapper">
            <Helmet>
                <title>obrabiarka.pl - artykuły CNC</title>
                <meta name="description" content="Ogłoszenia - maszyny i narzędzia CNC"/>
            </Helmet>
            <Row gutter={[16, 16]} className="margin-lr">
                <Col span={24}>
                    <h1 className="title-main">
                        Blog
                    </h1>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="blog-list-wrapper margin-lr">
                <Col span={24} lg={18}>
                    {htmlBlogList.map((blog) => {
                        return (
                            <div className="blog-card" key={blog.id} id={blog.attributes.slug}>
                                <Row gutter={[16, 16]}>
                                    <Col span={24} lg={10}>
                                        <div className="image-wrapper">
                                            <img
                                                src={blog.attributes.imageSquare.data.attributes.url}
                                                alt={blog.attributes.image.data.attributes.alternativeText}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={24} lg={14}>
                                        <div className="content-wrapper">
                                            <Paragraph className="card-title" ellipsis={{rows: 2}}>
                                                <h5>{blog.attributes.title}</h5>
                                            </Paragraph>
                                            <div className="card-date-container">
                                                <img src={dateIcon} alt="date-icon"/>
                                                <p className="card-date">
                                                    {new Date(blog.attributes.publishedAt).toLocaleDateString('pl-PL')}
                                                </p>
                                            </div>

                                            <Paragraph className="card-short-desc" ellipsis={{rows: 3}}>
                                                <p>{blog.attributes.shortDescription}</p>
                                            </Paragraph>
                                            <NavLink to={blog.attributes.slug}>
                                                <Space className='thx-btn'>
                                                    <Button
                                                        className="ant-btn-primary"
                                                        name="amount"
                                                    >
                                                        Zobacz więcej
                                                    </Button>
                                                </Space>
                                            </NavLink>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )
                    })
                    }
                </Col>
                <Col span={0} lg={6}>
                    <Affix offsetTop={10}>
                        <div className="blog-nav-wrapper">
                            {htmlBlogList.map((el) => {
                                return (
                                    <div key={el.id} className="blog-nav-link">
                                        <a href={`#${el.attributes.slug}`}>
                                            <p>{el.attributes.title}</p>
                                        </a>
                                    </div>
                                )
                            })}
                        </div>
                    </Affix>

                </Col>
            </Row>
        </div>
    );
};

export default Blog;