import React from 'react';
import {Upload} from "antd";

const UploadProductImg = ({imageList, setImageList,onRemove, disabled}) => {
    const uploadConfig = {
        rules: [{ required: true }],
        name: 'file',
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': null
        },
        showUploadList: {
            showPreviewIcon: false
        },

        onChange({ fileList: newFileList }) {
            setImageList(newFileList)
        },
        beforeUpload: (file) => {
            setImageList([...imageList, file])
            return false;
        },
        imageList,
    }
    const uploadButton = (
        <div>
            +
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Dodaj zdjęcie
            </div>
        </div>
    );

    return (
        <>
            <Upload
                {...uploadConfig}
                listType="picture-card"
                fileList={[...imageList]}
                onRemove={onRemove}
                disabled={disabled}
            >
                {imageList.length >= 8 ? null : uploadButton}
            </Upload>
        </>
    );
};


export default UploadProductImg;