import {$host} from "../index";


export const fetchAbplanalpProducts = async () => {
    const {data} = await $host.get('/abplanalp-products?populate=*&sort=id')
    return data.data
}
export const fetchAbplanalpPageData = async () => {
    const {data} = await $host.get('/abplanalp-products-page')
    return data.data
}

