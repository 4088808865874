import axios from "axios";
import {Buffer} from 'buffer';

const base64encodedData = Buffer.from(
    process.env.REACT_APP_PRZELEWY24_USERNAME
    + ':'
    + process.env.REACT_APP_PRZELEWY24_PASSWORD)
    .toString('base64')


const $host = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
})

const $authHost = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

const $authHostPrzelewy24 = axios.create({
    baseURL: process.env.REACT_APP_PRZELEWY24_CORS,
    headers: {
        // 'Content-Type': 'application/json',
         // "Access-Control-Allow-Origin": "*",
        // "Cache-Control": "no-cache"
    },
    validateStatus: (status) => {
        return status >= 200 && status < 500
    },
})

const authInterceptor = config => {
    config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
    return config
}


const authPrzelewy24Interceptor = config => {
    config.headers.authorization = `Basic ${base64encodedData}`
    return config
}

$authHost.interceptors.request.use(authInterceptor)
$authHostPrzelewy24.interceptors.request.use(authPrzelewy24Interceptor)


export {
    $host,
    $authHost,
    $authHostPrzelewy24
}