import {$host} from "../index";


export const fetchPolicyPrivacyPage = async () => {
    const {data} = await $host.get('/policy-privacy')
    return data.data
}
export const fetchAgreementPage = async () => {
    const {data} = await $host.get('/agreement')
    return data.data
}
export const fetchPolicyCookiesPage = async () => {
    const {data} = await $host.get('/policy-cookie')
    return data.data
}