import {useContext, useEffect, useState} from "react";
import {BrowserRouter} from "react-router-dom";
import {observer} from "mobx-react-lite";
import * as TagManager from "react-gtm-module";
import Hotjar from '@hotjar/browser'
import {Spin} from "antd";

import {Context} from "./index";
import {getUserData} from "./http/userAPI";
import AppRouter from "./components/AppRouter";

import "antd/dist/antd.less";
import "./style/components.less"


const App = observer(() => {
    const {user} = useContext(Context)
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        TagManager.initialize({gtmId: 'GTM-55XNBQ6'});
    }, []);

    useEffect(() => {
        const siteId = 5093241;
        const hotjarVersion = 6;
            Hotjar.init(siteId, hotjarVersion);
    }, []);

    useEffect(() => {
        const authorization = localStorage.getItem('token')
        if (authorization) {
            getUserData().then(data => {
                user.setUser(data)
                user.setIsAuth(true)
            }).finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [user.isAuth])



    if (loading) {
        return <Spin size="large" className="spin-main"/>
    }

    return (
        <BrowserRouter>
            <AppRouter/>
        </BrowserRouter>
    );
})

export default App;
