import React, {useReducer, useState} from 'react';
import {Link} from "react-router-dom";
import {Button, Checkbox, Col, Form, Input, message, Row, Select, Space, Spin, Tooltip} from "antd";

import {createUserProductAd, updateUserProductAd, uploadImageUserProductAd} from "../../../http/productAdAPI";
import UploadProductImg from "../../../components/pages/addProduct/UploadProductImg";
import {updateUserData} from "../../../http/userAPI";
import {ACCOUNT_ROUTE, ACCOUNT_ROUTE_PACKAGES} from "../../../utils/consts";

import './style.less'
import {Helmet} from "react-helmet";


const AddProduct = ({user, categories, voivodeships, promoPrices}) => {
    const [imageList, setImageList] = useState([])
    const [userProductAd, setUserProductAd] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            title: '',
            model: '',
            year: '',
            condition: '',
            price: '',
            isVat: false,
            withoutPrice: false,
            isDisplayHome: false,
            description: '',
            voivodeship: '',
            city: '',

            user: `${user.user.id}`,
            category: '',
            subCategory: '',
            endPromo: '',
            startPromo: ''
        }
    )
    const [subCategoryList, setSubCategoryList] = useState([])
    const [disableSelect, setDisableSelect] = useState(true)
    const [reqPrice, setReqPrice] = useState(false)
    const [finishPrice, setFinishPrice] = useState(0)
    const [isHasAmount, setIsHasAmount] = useState(true)
    const {Option} = Select
    const {TextArea} = Input
    const formData = new FormData()
    const [form] = Form.useForm()
    const [loader, setLoader] = useState(false)


    const click = async () => {
        let idUser = user.user.id;
        setLoader(true)
        try {
            if (user.user.amount < promoPrices.product + finishPrice) {
                setIsHasAmount(false)
            } else {
                let resProduct = await createUserProductAd(userProductAd)

                    imageList.forEach(image => {
                        formData.append('files', image.originFileObj)
                    })
                    formData.append('ref', 'api::product.product');
                    formData.append('refId', `${resProduct.data.id}`);
                    formData.append('field', 'gallery');

                let resImageUpload = await uploadImageUserProductAd(formData)

                await updateUserProductAd(resProduct.data.id, {imageLink: resImageUpload[0].url})


                user.setUser({...user.user, amount: user.user.amount - promoPrices.product - finishPrice})
                await updateUserData(idUser, user.user)

                setLoader(false)
                message.success("Ogłoszenie dodane")
                setTimeout(() => {
                    window.location.href=ACCOUNT_ROUTE
                }, 1000)
            }
        } catch (e) {
            console.log("err", e.response.data.error)
        }
    }
    const handleCategoryChange = (value, id) => {
        setUserProductAd({...userProductAd, category: id.key})
        categories.map((cat) => {
            if (cat.attributes.slug === value) {
                setSubCategoryList(cat.attributes.subCategories.data)
            }
        })
        setDisableSelect(false)
    }
    const handleChange = (e) => {
        const {name, value, checked} = e.target
        if (e.target.type === "checkbox") {
            setUserProductAd({...userProductAd, [name]: checked})
        } else {
            setUserProductAd({...userProductAd, [name]: value})
        }
    }
    const validateMessages = {
        required: "Pole ${label} jest wymagane!",
    }

    let newDate = new Date()
    newDate.setDate(newDate.getDate() + 30)
    let finishDate = new Date(newDate)

    const handlePromo = (e) => {
        if (e.target.checked) {
            setUserProductAd({isDisplayHome: e.target.checked, endPromo: finishDate})
            setFinishPrice((prev) => prev + promoPrices.isShowHomePage)
        } else {
            setUserProductAd({isDisplayHome: e.target.checked, endPromo: ''})
            setFinishPrice((prev) => prev - promoPrices.isShowHomePage)
        }
    }

    return (
        <div className="page-container add-product-wrapper">
            <Helmet>
                <title>Sprzedaj maszynę CNC</title>
            </Helmet>
            {loader ? <Spin size="large" className="overlay-loading"/>: ''}
            {user.user.amount < promoPrices.product ?
                <div className="overlay-add-product">
                    <p>Nie masz wystarczających środków na koncie dla wystawienia ogłoszenia. Doładuj saldo Konta.</p>
                    <Link to={ACCOUNT_ROUTE_PACKAGES}>
                        <Space className='btn-wrapper-right btn-ad'>
                            <Button style={{display: "block"}}
                                    type="primary">
                                Doładuj konto
                            </Button>
                        </Space>
                    </Link>
                </div>
                :
                <Form
                    form={form}
                    validateMessages={validateMessages}
                    name=""
                    layout="vertical"
                    onFinish={click}
                    autoComplete="off"
                >
                    <h3>Wybierz kategorię</h3>
                    <Row gutter={[16, 16]} className="m-0">
                        <Col span={24} sm={12} md={6}>
                            <Form.Item
                                name="category"
                                label="Kategoria"
                                rules={[{required: true}]}
                            >
                                <Select placeholder="Wybierz kategorię"
                                        name="category"
                                        onChange={handleCategoryChange}
                                >
                                    {categories.map((el) =>
                                        <Option key={el.id} value={el.attributes.slug}>
                                            {el.attributes.name}
                                        </Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={12} md={6}>
                            <Form.Item
                                name="subCategory"
                                label="Podkategoria"
                                rules={[{required: true}]}
                            >
                                <Select placeholder="Wybierz podkategorię"
                                        name="subCategory"
                                        onChange={(value, id) => {
                                            setUserProductAd({...userProductAd, subCategory: id.key})
                                        }}
                                        disabled={disableSelect}
                                >
                                    {subCategoryList.map((el) =>
                                        <Option key={el.id} value={el.attributes.slug}>
                                            {el.attributes.name}
                                        </Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <h3>Szczegóły</h3>
                    <Row align="bottom" gutter={[16, 16]} className="m-0">
                        <Col span={24} sm={12} md={6}>
                            <Form.Item
                                name="title"
                                label="Tytuł ogłoszenia"
                                rules={[{required: true}]}
                            >
                                <Input
                                    maxLength={60}
                                    placeholder='Tytuł ogłoszenia'
                                    name="title"
                                    onChange={e => handleChange(e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={12} md={6}>
                            <Form.Item
                                name="producer"
                                label="Producent"
                                rules={[{required: true}]}
                            >
                                <Input
                                    placeholder='Producent'
                                    name="producer"
                                    onChange={e => handleChange(e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={12} md={6}>
                            <Form.Item
                                name="model"
                                label="Model"
                                rules={[{required: true}]}
                            >
                                <Input
                                    placeholder="Model"
                                    name="model"
                                    onChange={e => handleChange(e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={12} md={6}>
                            <Form.Item
                                name="year"
                                label="Rok produkcji"
                            >
                                <Input
                                    placeholder="Rok produkcji"
                                    name="year"
                                    type="number"
                                    onChange={e => handleChange(e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={12} md={6}>
                            <Form.Item
                                name="condition"
                                label="Stan"
                                rules={[{required: true}]}
                            >
                                <Select
                                    name="condition"
                                    placeholder='Wybierz'
                                    onChange={value => {
                                        setUserProductAd({...userProductAd, condition: value})
                                    }}
                                >
                                    <Option value="Nowy">Nowy</Option>
                                    <Option value="Używany">Używany</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={12} md={6}>
                            <Form.Item
                                name="price"
                                label="Cena"
                                rules={[{required: !reqPrice}]}
                            >
                                <Input
                                    placeholder='Cena'
                                    name="price"
                                    suffix="zł"
                                    disabled={reqPrice}
                                    type="number"
                                    onChange={e => handleChange(e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8} sm={4}>
                            <Form.Item
                                name="isVat"
                                valuePropName="checked"
                            >
                                <Checkbox
                                    name="isVat"
                                    disabled={reqPrice}
                                    onChange={(e) => handleChange(e)}
                                >
                                    + VAT
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={16} sm={12} md={6}>
                            <Form.Item
                                name="withoutPrice"
                                valuePropName="checked"
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            setReqPrice(value)
                                            return Promise.resolve();
                                        }
                                    })
                                ]}
                            >
                                <Checkbox
                                    name="withoutPrice"
                                    onChange={(e) => handleChange(e)}
                                >
                                    Cena do uzgodnienia
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label="Opis"
                                rules={[{required: true}]}
                            >
                                <TextArea
                                    showCount
                                    name="description"
                                    rows={4}
                                    placeholder="Maks 5000 znaków"
                                    maxLength={5000}
                                    onChange={(e) => handleChange(e)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <h3>Galeria</h3>
                    <Row gutter={[16, 16]} className="m-0">
                        <Col span={24}>
                            <Form.Item
                                name="gallery"
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            if (!imageList.length) {
                                                return Promise.reject('Prosze dodać obrazek');
                                            } else {
                                                return Promise.resolve();
                                            }
                                        }
                                    })
                                ]}
                            >
                                <UploadProductImg setImageList={setImageList} imageList={imageList}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <h3>Lokalizacja</h3>
                    <Row gutter={[16, 16]} className="m-0">
                        <Col span={24} sm={12} md={6}>

                            <Form.Item
                                name="voivodeship"
                                label="Województwo"
                                rules={[{required: true}]}
                            >
                                <Select placeholder="Wybierz województwo"
                                        name="voivodeship"
                                        showSearch
                                        getPopupContainer={trigger => trigger.parentElement}
                                        onChange={(_, el) => {
                                            setUserProductAd({...userProductAd, voivodeship: el.key})
                                        }}
                                >
                                    {voivodeships.map((el,key) =>
                                        <Option key={el.id} value={el.attributes.slug}>{el.attributes.label}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={12} md={6}>
                            <Form.Item
                                name="city"
                                label="Miejscowość"
                                rules={[{required: true}]}
                            >
                                <Input
                                    name="city"
                                    onChange={e => handleChange(e)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <h3>Promowanie</h3>
                    <Row gutter={[16, 16]} className="m-0">
                        <Col span={24}>
                                <Form.Item
                                    name="isDisplayHome"
                                    label="Strona główna"
                                    valuePropName="checked"
                                >
                                    <Tooltip
                                        open={user.user.amount < promoPrices.product + promoPrices.isShowHomePage ?
                                            true : ''}
                                        placement="topLeft" title="Nie masz środków na koncie">
                                    <Checkbox
                                        name="isDisplayHome"
                                        disabled={user.user.amount < promoPrices.product + promoPrices.isShowHomePage ?
                                            true : ''
                                        }
                                        onChange={(e) => {
                                            handlePromo(e)
                                        }}
                                    >
                                        Wyświetlanie na stronie głównej <strong>(+ {promoPrices.isShowHomePage} zł)</strong>
                                    </Checkbox>
                                    </Tooltip>
                                </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {!isHasAmount ?
                                <div>
                                    Panie, doładuj
                                    <Link
                                        to={ACCOUNT_ROUTE_PACKAGES}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >konto </Link>
                                </div>
                                :
                                ''
                            }
                        </Col>
                    </Row>
                    <Space className='btn-wrapper'>
                        <Button style={{display: "block"}}
                                type="primary"
                                htmlType="submit"
                                disabled={!isHasAmount}
                        >
                            Publikuj ogłoszenie ({promoPrices.product + finishPrice} zł)
                        </Button>
                    </Space>
                </Form>
            }
        </div>
    );
};

export default AddProduct;