import React, {useEffect, useReducer, useState} from 'react';
import {useParams} from "react-router-dom";
import {Button, Checkbox, Col, Form, Input, message, Row, Space, Tooltip} from "antd";

import {fetchUserProductOne, removeUserProductsImage, updateUserProductAd} from "../../../http/productAdAPI";
import UploadProductImg from "../../../components/pages/addProduct/UploadProductImg";
import {updateUserData} from "../../../http/userAPI";
import {ACCOUNT_ROUTE_MY_PRODUCTS} from "../../../utils/consts";
import {Helmet} from "react-helmet";


const UpdateProduct = ({promoPrices, user}) => {
    const {id} = useParams()
    const [form] = Form.useForm()
    const [userProductAd, setUserProductAd] = useReducer(
        (state, newState) => ({...state, ...newState}), {})
    const [imageList, setImageList] = useState([])
    const [finishPrice, setFinishPrice] = useState(0)
    const {TextArea} = Input
    let newUpdateDate = new Date()
    let finishDate = new Date(newUpdateDate.setDate(newUpdateDate.getDate() + 30))

    useEffect(() => {
        fetchUserProductOne(id).then(data => {
            const isDateEmpty = data.attributes.endPromo === '';
            form.setFieldsValue({
                ...data.attributes,
                category: data.attributes.category.data.attributes.name,
                subCategory: data.attributes.subCategory.data.attributes.name,
                voivodeship: data.attributes.voivodeship.data.attributes.label,
                endPromo: data.attributes.endPromo,
                isDisplayHome: isDateEmpty ? false :
                    new Date() > new Date(data.attributes.endPromo) ? false :
                        data.attributes.isDisplayHome
            })
            setUserProductAd({user: data.attributes.user.data.id})

            if (data.attributes.gallery.data) {
                let tempArray = [...data.attributes.gallery.data]
                tempArray.findIndex((element, key) => {
                    tempArray[key] = {
                        ...tempArray[key],
                        thumbUrl: tempArray[key].attributes.url
                    }
                })
                setImageList(tempArray)
            }
        })
    }, [])

    const click = async () => {
        let idUser = user.user.id;
        try {
            let data = await updateUserProductAd(id, userProductAd)
            await updateUserProductAd(id, userProductAd)
            user.setUser({...user.user, amount: user.user.amount - finishPrice})
            await updateUserData(idUser, user.user)
            message.success("Ogłoszenie zaktualizowane")
            setTimeout(() => {
                window.location.href = ACCOUNT_ROUTE_MY_PRODUCTS
            }, 1000)
        } catch (e) {
            console.log("err", e.response.data.error)
        }
    }
    const handleRemoveImage = async (e) => {
        try {
            if (!e.originFileObj) {
                let data = await removeUserProductsImage(e.id).then((d) => {
                        setUserProductAd((prev) => prev.filter((data) => data.id !== e.id))
                    }
                )
            }
        } catch (e) {
            console.log(e)
        }
    }
    const handleChange = (e) => {
        const {name, value, checked} = e.target
        if (e.target.type === "checkbox") {
            setUserProductAd({...userProductAd, [name]: checked})
        } else {
            setUserProductAd({...userProductAd, [name]: value})
        }
    }

    const handleUpdatePromo = (e) => {
        if (e.target.checked) {
            setUserProductAd({isDisplayHome: e.target.checked, endPromo: finishDate})
            setFinishPrice((prev) => prev + promoPrices.isShowHomePage)
        } else {
            setUserProductAd({isDisplayHome: e.target.checked, endPromo: ''})
            setFinishPrice((prev) => prev - promoPrices.isShowHomePage)
        }
    }
    const validateMessages = {
        required: "Pole ${label} jest wymagane!",
    }

    return (
        <div className="page-container">
            <Helmet>
                <title>Edycja ogłoszenia</title>
            </Helmet>
            <Form
                form={form}
                validateMessages={validateMessages}
                name=""
                layout="vertical"
                onFinish={click}
                autoComplete="off"
            >
                <h3>Wybierz kategorię</h3>
                <Row gutter={[16, 16]} className="m-0">
                    <Col span={24} sm={12} md={6}>
                        <Form.Item
                            name="category"
                            label="Kategoria"
                        >
                            <Input
                                placeholder='Wybierz kategorię'
                                name="category"
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={12} md={6}>
                        <Form.Item
                            name="subCategory"
                            label="Podkategoria"
                        >
                            <Input
                                placeholder='Wybierz podkategorię'
                                name="subCategory"
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <h3>Szczegóły</h3>
                <Row align="bottom" gutter={[16, 16]} className="m-0">
                    <Col span={24} sm={12} md={6}>
                        <Form.Item
                            name="title"
                            label="Tytuł ogłoszenia"
                            rules={[{required: true}]}
                        >
                            <Input
                                placeholder='Tytuł ogłoszenia'
                                name="title"
                                onChange={e => handleChange(e)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={12} md={6}>
                        <Form.Item
                            name="producer"
                            label="Producent"
                        >
                            <Input
                                placeholder='Producent'
                                name="producer"
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={12} md={6}>
                        <Form.Item
                            name="model"
                            label="Model"
                        >
                            <Input
                                placeholder='Model'
                                name="model"
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={12} md={6}>
                        <Form.Item
                            name="year"
                            label="Rok produkcji"
                        >
                            <Input
                                placeholder='Rok produkcji'
                                name="year"
                                type="number"
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={12} md={6}>
                        <Form.Item
                            name="condition"
                            label="Stan"
                        >
                            <Input
                                name="condition"
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={12} md={6}>
                        <Form.Item
                            name="price"
                            label="Cena"
                            rules={[{required: true}]}
                        >
                            <Input
                                placeholder='Cena'
                                name="price"
                                suffix="zł"
                                disabled={form.getFieldValue("withoutPrice")}
                                type="number"
                                onChange={e => handleChange(e)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8} sm={4}>
                        <Form.Item
                            name="isVat"
                            valuePropName="checked"
                        >
                            <Checkbox
                                name="isVat"
                                disabled={form.getFieldValue("withoutPrice")}
                                onChange={(e) => handleChange(e)}
                            >
                                + VAT
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={16} sm={12} md={6}>
                        <Form.Item
                            name="withoutPrice"
                            valuePropName="checked"
                            rules={[
                                ({getFieldValue, setFieldsValue}) => ({
                                    validator() {
                                        if (getFieldValue("withoutPrice")) {
                                            setFieldsValue({price: "0"})
                                            setUserProductAd({...userProductAd, price: "0"})
                                            return Promise.resolve()
                                        }
                                        return Promise.resolve()
                                    }
                                })
                            ]}
                        >
                            <Checkbox
                                name="withoutPrice"
                                onChange={(e) => handleChange(e)}
                            >
                                Cena do uzgodnienia
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="description"
                            label="Opis"
                        >
                            <TextArea
                                showCount
                                name="description"
                                rows={4}
                                placeholder="maks 5000 znaków"
                                maxLength={5000}
                                rules={[{required: true}]}
                                onChange={(e) => handleChange(e)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <h3>Galeria</h3>
                <Row gutter={[16, 16]} className="m-0">
                    <Col span={24}>
                        <Form.Item name="gallery">
                            <UploadProductImg
                                setImageList={setImageList}
                                imageList={imageList}
                                onRemove={handleRemoveImage}
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <h3>Lokalizacja</h3>
                <Row gutter={[16, 16]} className="m-0">
                    <Col span={24} sm={12} md={6}>
                        <Form.Item
                            name="voivodeship"
                            label="Województwo"
                        >
                            <Input
                                name="voivodeship"
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={12} md={6}>
                        <Form.Item
                            name="city"
                            label="Miejscowość"
                        >
                            <Input
                                name="city"
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <h3>Promowanie</h3>
                <Row gutter={[16, 16]} className="m-0">
                    <Col span={24}>
                        <Form.Item
                            name="isDisplayHome"
                            label="Strona główna"
                            valuePropName="checked"
                        >
                            <Tooltip
                                // open={user.user.amount < promoPrices.product + promoPrices.isShowHomePage ?
                                //     true : ''}

                                open={user.user.amount <= promoPrices.isShowHomePage ?
                                    true : ''}
                                placement="topLeft" title="Nie masz środków na koncie">
                            <Checkbox
                                name="isDisplayHome"
                                //disabled={form.getFieldsValue().isDisplayHome}
                                disabled={user.user.amount <= promoPrices.isShowHomePage || form.getFieldsValue().isDisplayHome ?
                                    true : ''
                                }
                                onChange={(e) => {
                                    handleUpdatePromo(e)
                                }}
                            >
                                Wyświetlanie na stronie głównej <strong>(+ {promoPrices.isShowHomePage} zł)</strong>
                            </Checkbox>
                            </Tooltip>
                        </Form.Item>
                    </Col>
                </Row>
                <Space className='btn-wrapper'>
                    <Button style={{display: "block"}}
                            type="primary"
                            htmlType="submit"
                    >
                        Zapisz zmiany ({finishPrice} zł)
                    </Button>
                </Space>
            </Form>
        </div>
    );
};

export default UpdateProduct;