import {$host} from "./index";

export const fetchVoivodeship = async () => {
    const {data} = await $host.get('/voivodeships?populate=*')
    return data.data
}

export const sendContactForm = async (formDataContact) => {
    const {data} = await $host.post('/supports', {data:{...formDataContact}})
    return data.data
}

export const fetchTemplate = async () => {
    const {data} = await $host.get('/template?populate=*')
    return data.data
}
