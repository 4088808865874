import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Space, Typography} from "antd";

import {fetchAbplanalpPageData, fetchAbplanalpProducts} from "../../http/pages/abplanalpAPI";

import './style.less'
import {Helmet} from "react-helmet";

const AbplanalpProducts = () => {
    const [abplanalpProducts, setAbplanalpProducts] = useState([])
    const [abplanalpData, setAbplanalpData] = useState({})
    const {Paragraph} = Typography;

    useEffect(() => {
        fetchAbplanalpPageData().then(data => setAbplanalpData(data.attributes))
        fetchAbplanalpProducts().then(data => setAbplanalpProducts(data))
    }, [])

    return (
        <div className="page-wrapper state-products-wrapper">
            <Helmet>
                <title>Narzędzia do obrabiarek</title>
            </Helmet>
            <Row gutter={[0, 16]} justify="center">
                <Col span={24}>
                    <h1 className="title-main">
                        {abplanalpData.title}
                    </h1>
                    <p className="desc">
                        {abplanalpData.description}
                    </p>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="products-list margin-lr">
                {abplanalpProducts.map((el) => {
                    return (
                        <Col key={el.id} span={24} sm={12} md={8} lg={6}>
                            <div className="card-wrapper">
                                <a
                                    href={el.attributes.slug}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        className="product-img"
                                        src={el.attributes.image.data.attributes.url}
                                        alt={el.attributes.image.data.attributes.alternativeText}
                                    />
                                    {el.attributes.logo.data ?
                                        <img
                                            className="product-logo"
                                            src={el.attributes.logo.data.attributes.url}
                                            alt={el.attributes.logo.data.attributes.alternativeText}
                                        />
                                        :
                                        ''
                                    }
                                </a>
                                <div className="info">
                                    <Paragraph className="card-title" ellipsis={{rows: 2}}>
                                        {el.attributes.title}
                                    </Paragraph>
                                    <a
                                        href={el.attributes.slug}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Space className='btn-wrapper-center thx-btn'>
                                            <Button
                                                className="ant-btn-primary"
                                                name="amount"
                                            >
                                                Zobacz więcej
                                            </Button>
                                        </Space>
                                    </a>
                                </div>
                            </div>
                        </Col>
                    )
                })
                }
            </Row>
        </div>
    );
};

export default AbplanalpProducts;