import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import UserStore from "./store/UserStore";
import ProductAdStore from "./store/ProductStore";
import GlobalStore from "./store/GlobalStore";

import './style/index.css';


export const Context = createContext(null)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Context.Provider value={{
        user: new UserStore(),
        product: new ProductAdStore(),
        global: new GlobalStore()
    }}>
        <App />
    </Context.Provider>
);
