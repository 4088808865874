import React from 'react';
import {connectInfiniteHits} from "react-instantsearch-dom";
import {Col} from "antd";

import CardCustomGrid from "../../components/Card/CardCustomGrid";


const Hits = ({hits, hasMore, refineNext, searchStateConditional}) => {
    // let format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    return (
        <>
            {hits.map((hit, i, {length}) => {
                return (
                    <React.Fragment key={hit.id}>
                        <Col span={24} className="custom-col">
                            <CardCustomGrid item={hit}/>
                        </Col>
                        {i + 1 === length ?
                            <div className={`button-wrapper-more ${hasMore ? 'show-more' : 'show-more-disabled'}`}>
                                <button onClick={refineNext}>
                                    Załaduj więcej
                                </button>
                            </div>
                            :
                            ''
                        }
                    </React.Fragment>
                )
            })
            }

        </>
    )
}
const CustomHits = connectInfiniteHits(Hits);
export default CustomHits;