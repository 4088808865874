import React, {useEffect, useReducer, useState} from "react";
import {Link} from "react-router-dom";
import sha384 from 'crypto-js/sha384';
import {Button, Col, Row, Space, Spin} from "antd";

import {createOrder, notificationTransaction, verifyTransaction} from "../../../http/przelewy24API";
import {updateUserData} from "../../../http/userAPI";
import {ACCOUNT_ROUTE_ADD_PRODUCT, HOME_ROUTE} from "../../../utils/consts";

import './style.less'
import {Helmet} from "react-helmet";


const PaymentStatus = ({user}) => {
    let getLocalOrder = localStorage.getItem('order')
    let parseOrder = JSON.parse(getLocalOrder)
    const [loading, setLoading] = useState(true)
    const [paymentAmount, setPaymentAmount] = useState(0)
    const [userAmount, setUserAmount] = useState('')
    const [verifyPaymentData, setVerifyPaymentData] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            "merchantId": 0,
            "posId": 0,
            "sessionId": "string",
            "amount": 0,
            "currency": "PLN",
            "orderId": 0,
            "sign": "string"
        })

    const getVerifyData = async () => {
        try {
            await notificationTransaction(parseOrder.sessionID).then((data) => {
                console.log('')
                setPaymentAmount(data.amount)
                setUserAmount(data.description.match(/\d/g).join(""))
                const dataVerifySHA = sha384(JSON.stringify({
                    "sessionId": parseOrder.sessionID,
                    "orderId": data.orderId,
                    "amount": data.amount,
                    "currency": data.currency,
                    "crc": process.env.REACT_APP_PRZELEWY24_CRC
                }))
                return (
                    setVerifyPaymentData({
                        merchantId: parseInt(process.env.REACT_APP_PRZELEWY24_MERCHANT_ID),
                        posId: parseInt(process.env.REACT_APP_PRZELEWY24_MERCHANT_ID),
                        sessionId: parseOrder.sessionID,
                        amount: data.amount,
                        currency: data.currency,
                        orderId: data.orderId,
                        sign: dataVerifySHA.toString(),
                    })
                )
            })
        } catch (e) {
            console.log("err", e.response.data.error)
        }

    }
    useEffect(() => {
        getVerifyData();
    }, [])
    useEffect(() => {
        if (verifyPaymentData.merchantId === 0) {
            return;
        } else {
            verifyTransaction(verifyPaymentData)
                .then((data) => {
                    let idUser = user.user.id;
                    setLoading(false)
                    // data.status === 'success' &&
                    if ( data.status === 'success' && !user.user.isPay) {
                        createOrder({
                            "confirmed": true,
                            "user": user.user.id,
                            "payment": parseOrder.payment,
                            "sessionId": parseOrder.sessionID,
                            "amount": paymentAmount / 100,
                            "invoices": parseOrder.invoice ? parseOrder.invoice : false
                        })

                        user.setUser({...user.user, isPay: true})
                        user.setUser({...user.user, amount: user.user.amount + parseInt(userAmount)})
                        updateUserData(idUser, user.user)
                    }
                }).catch(err => {
                console.log(err)
            })
        }

    }, [verifyPaymentData.merchantId])

    if (loading) {
        return <Spin size="large" className="spin-main"/>
    }
    return (
        <div className="status-wrapper">
            <Helmet>
                <title>Status płatności</title>
            </Helmet>
            <Row>
                {user.user.isPay ?
                    <Col span={24} className="thx-text-container">
                        <p className="thx-amount">+ {userAmount} zł</p>
                        <p className="thx-text">Konto zostało doładowane</p>
                    </Col>
                    :
                    <Col span={24} className="thx-text-container">
                        <p className="thx-text">Transakcja nie powiodła się</p>
                    </Col>
                }

                <Col span={12}>
                    <Space className='btn-wrapper-center thx-btn'>
                        <Link to={HOME_ROUTE}>
                            <Button style={{display: "block"}}
                                    type="primary">
                                Strona główna
                            </Button>
                        </Link>
                    </Space>
                </Col>
                <Col span={12}>
                    <Space className='btn-wrapper-center btn-ad'>
                        <Link to={ACCOUNT_ROUTE_ADD_PRODUCT}>
                            <Button style={{display: "block"}}
                                    type="primary">
                                Dodaj ogłoszenie
                            </Button>
                        </Link>
                    </Space>
                </Col>
            </Row>
        </div>
    );
};

export default PaymentStatus;