import React from 'react';
import {Button, Col, Form, Input, message, Row, Select, Space} from "antd";

import {updateUserData} from "../../http/userAPI";
import {Helmet} from "react-helmet";

const InfoAccount = ({user, voivodeships}) => {
    const {Option} = Select;

    const handleChange = (e) => {
        const {name, value} = e.target
        user.setUser({...user.user, [name]: value})
    }
    const click = async () => {
        let id = user.user.id;
        try {
            await updateUserData(id, user.user)
            message.success("Dane zaktualizowane")
        } catch (e) {
            console.log("err", e.response.data.error)
        }
    }
    return (
        <div className="info-wrapper">
            <Helmet>
                <title>Moje Konto</title>
            </Helmet>
            <Form
                initialValues={user.user}
                name=""
                layout="vertical"
                onFinish={click}
                autoComplete="off"
            >
                <Row justify="space-between" align="top" className="m-0" gutter={[16, 16]}>
                    <Col span={24} lg={8}>
                        <h3>Dane kontaktowe</h3>
                        <Form.Item
                            name="fullName"
                            label="Imię i nazwisko"
                            rules={[{
                                required: true,
                                message: 'Wpisz imię i nazwisko.',
                                whitespace: true
                            }]}
                        >
                            <Input
                                maxLength={50}
                                placeholder='Imię i nazwisko'
                                name="fullName"
                                onChange={e => handleChange(e)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            label="Telefon"
                            rules={[{
                                required: true, message: 'Wpisz numer telefonu.'
                            }]}
                        >
                            <Input
                                maxLength={15}
                                placeholder='Telefon'
                                name="phone"
                                onChange={e => handleChange(e)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={8}>
                        <h3>Dane do faktury</h3>
                        <Form.Item
                            name="voivodeship"
                            label="Województwo"
                        >
                            <Select placeholder="Wybierz województwo"
                                    name="voivodeship"
                                    onChange={(_, el) => {
                                        user.setUser({...user.user, voivodeship: el.children})
                                    }}
                            >
                                {voivodeships.map((el) =>
                                    <Option key={el.id} value={el.attributes.slug}>{el.attributes.label}</Option>
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="city"
                            label="Miejscowość"
                        >
                            <Input
                                maxLength={40}
                                name="city"
                                onChange={e => handleChange(e)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="street"
                            label="Ulica / nr domu"
                        >
                            <Input
                                maxLength={60}
                                name="street"
                                onChange={e => handleChange(e)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="postalCode"
                            label="Kod pocztowy"
                        >
                            <Input
                                maxLength={6}
                                name="postalCode"
                                onChange={e => handleChange(e)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={8}>
                        <h3>Firma</h3>
                        <Form.Item
                            name="companyName"
                            label="Nazwa firmy"
                        >
                            <Input
                                maxLength={40}
                                placeholder='Nazwa firmy'
                                name="companyName"
                                onChange={e => handleChange(e)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="nip"
                            label="NIP"
                        >
                            <Input
                                maxLength={10}
                                placeholder='NIP'
                                name="nip"
                                onChange={e => handleChange(e)}
                            />
                        </Form.Item>
                    </Col>
                    <Space className='btn-wrapper'>
                        <Button style={{display: "block"}}
                                type="primary"
                                htmlType="submit">
                            Zapisz zmiany
                        </Button>
                    </Space>
                </Row>
            </Form>
        </div>
    );
};

export default InfoAccount;