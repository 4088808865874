import {makeAutoObservable, toJS} from "mobx";

export default class UserStore {
    constructor() {
        this._isAuth = false
        this._user = {
            fullName: '',
            companyName: '',
            nip: '',
            phone: '',
            email: '',
            password: '',
            agreementGDPR: false,
            agreementPolicy: false,
        }
        this._userProductsAd = []
        this._userFav = []
        this._userOrders = []
        makeAutoObservable(this)
    }

    setIsAuth(bool) {
        this._isAuth = bool
    }

    setUser(user) {
        this._user = user
    }

    setUserProductsAd(userProductsAd) {
        // this._userProductsAd = userProductsAd
        let userProducts = []
        userProductsAd.map((item) => {
            if (item.publishedAt !== null) {
                userProducts.push(item)
            }
        })
        this._userProductsAd = userProducts
    }

    setUserFav(userFav) {
        this._userFav = userFav
    }

    setUserOrders(userOrders) {
        this._userOrders = userOrders
    }

    get isAuth() {
        return this._isAuth
    }

    get user() {
        return toJS(this._user)
    }

    get userProductsAd() {
        return toJS(this._userProductsAd)
    }

    get userFav() {
        return toJS(this._userFav)
    }

    get userOrders() {
        return toJS(this._userOrders)
    }
}