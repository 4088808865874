import React from "react";
import {NavLink} from "react-router-dom";
import {Button, Space} from "antd";

import {HOME_ROUTE} from "../../utils/consts";

import './style.less'
import image404 from '../../assets/img/404.png'
import {Helmet} from "react-helmet";


const NotFound = () => {
    return (
        <div className="page-wrapper not-found-wrapper">
            <Helmet>
                <title>404</title>
            </Helmet>
            <div className="not-found-info-wrapper">
                <div className="info-container">
                    <img
                        src={image404}
                         alt="404"
                    />
                    <h1 className="main-title">
                        Wygląda na to, że ta strona nie istnieje
                    </h1>
                    <div className="desc">
                        Sprawdź poprawność wpisanego linku
                    </div>
                    <Space className='btn-wrapper'>
                        <Button type="primary">
                            <NavLink to={HOME_ROUTE}>
                                Wróć na stronę główną
                            </NavLink>
                        </Button>
                    </Space>
                </div>

            </div>

        </div>
    );
};

export default NotFound;