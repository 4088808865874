import React, {useContext} from 'react';
import {NavLink} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Statistic, Typography} from "antd";

import {Context} from "../../index";
import {SHOP_ROUTE} from "../../utils/consts";

import './style.less'
import AddToFav from "../UI/AddToFav/AddToFav";

const CardCustom = observer(({item}) => {
    const {user} = useContext(Context)
    const {Paragraph} = Typography;

    let imageSrc = item.attributes ?
        item.attributes.gallery.data[0].attributes.url : item.imageUrl;
    let slug = item.attributes ? item.attributes.slug : item.slug;
    let title = item.attributes ? item.attributes.title : item.title;
    let voivodeship = item.attributes ?
        item.attributes.voivodeship.data.attributes.label : item.voivodeship[0];
    let city = item.attributes ? item.attributes.city : item.city;
    let price = item.attributes ? item.attributes.price : item.price;
    let isVat = item.attributes ? item.attributes.isVat : item.isVat;
    let withoutPrice = item.attributes ? item.attributes.withoutPrice : item.withoutPrice;


    return (
        <div className="card-container card-container-mobile">
            <NavLink to={SHOP_ROUTE + `/${slug}`}>
                <div className="image-wrapper">
                    <img
                        width="100%"
                        src={imageSrc}
                        alt="product"
                    />
                </div>
            </NavLink>
            <div className="card-content-wrapper">
                <NavLink to={SHOP_ROUTE + `/${slug}`}>
                    <Paragraph className="card-title" ellipsis={{rows: 2}}>
                        {title}
                    </Paragraph>
                </NavLink>
                <p className="card-location">
                    {voivodeship},
                    <span> {city}</span>
                </p>
                {withoutPrice ?
                    <p className="card-without-price">
                        Cena do uzgodnienia
                    </p>
                    :
                    <div className="card-price">
                        <Statistic value={price} precision={2} suffix="zł"/>
                        {isVat ? <span className="with-vat">+ VAT</span> : ''}
                    </div>
                }
                <AddToFav
                    item={item}
                    isActive={user.userFav.find(el => el.id === item.id)}
                />
            </div>
        </div>
    );
});

export default CardCustom;