import {$authHost, $host} from "../index";


export const fetchHomeHero = async () => {
    const {data} = await $host.get('/home?populate=hero.image')
    return data.data
}
export const fetchHomePage = async () => {
    const {data} = await $host.get('/home?populate=*')
    return data.data
}