import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Affix, Card, Carousel, Col, Row, Spin, Statistic} from "antd";

import {Context} from "../../index";
import {fetchProductOne} from "../../http/productAdAPI";
import AddToFav from "../../components/UI/AddToFav/AddToFav";

import "./ProductSingle.less"
import LocIcon from '../../assets/icons/loc.svg'
import {Helmet} from "react-helmet";


const ProductSingle = observer(() => {
    const {user} = useContext(Context)
    const {id} = useParams()
    const [productInfo, setProductInfo] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchProductOne(id).then(data => setProductInfo(data))
            .finally(() => setLoading(false))
    }, [id])

    if (loading) {
        return <Spin animation={"grow"}/>
    }

    return (
        <div className="page-wrapper product-single-wrapper">
            <Helmet>
                <title>obrabiarka.pl | {productInfo.attributes.title}</title>
            </Helmet>
            <Row className="m-0" gutter={[12, 16]}>
                <Col span={24} xl={16} className="left-col-wrapper">
                    <Row gutter={[0, 16]}>
                        <Col
                            className="product-single-slider"
                            span={24}
                        >
                            <Carousel
                                arrows
                            >
                                {productInfo.attributes.gallery.data.map((el) =>
                                    <div
                                        className="slide-wrapper"
                                        key={el.id}
                                    >
                                        <img src={el.attributes.url} alt={productInfo.attributes.title}/>
                                    </div>
                                )}
                            </Carousel>
                        </Col>
                        <Col className="product-single-location" span={24}>
                            <div>
                                <Row>
                                    <Col span={22} className="icon-loc">
                                        <img src={LocIcon} alt="location icon"/>
                                        <p>{productInfo.attributes.voivodeship.data.attributes.label
                                        + ', ' + productInfo.attributes.city}</p>
                                    </Col>
                                    <Col span={2}>
                                        <AddToFav
                                            item={productInfo}
                                            isActive={user.userFav.find(el => el.id === productInfo.id)}
                                        />
                                    </Col>
                                </Row>
                                <h1 className="title-main">{productInfo.attributes.title}</h1>
                                <div className="price">
                                    {productInfo.attributes.withoutPrice ?
                                        <p className="card-without-price">
                                            Cena do uzgodnienia
                                        </p>
                                        :
                                        <div className="card-price">
                                            <Statistic value={productInfo.attributes.price} precision={2} suffix="zł"/>
                                            {productInfo.attributes.isVat ?
                                                <span className="with-vat">+ VAT</span> : ''}
                                        </div>
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col span={24} className="product-single-description">
                            <div className="card-bg">
                                <h3 className="title-main">Opis</h3>
                                <p>{productInfo.attributes.description}</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} xl={8} className="gutter-row right-col-wrapper">
                    <Affix offsetTop={35} target={window.innerWidth > 1200 ? undefined : null}>
                        <Row gutter={[0, 12]}>
                            <Col span={24}>
                                <Card className="product-single-user" title="Skontaktuj się ze Sprzedawcą"
                                      bordered={false}>
                                    <p className="item-info username">
                                        <strong>{productInfo.attributes.user.data.attributes.fullName}</strong>
                                    </p>
                                    <p className="item-info">
                                        <label>Telefon:</label>
                                        <strong>
                                            <a href={`tel:${productInfo.attributes.user.data.attributes.phone}`}>
                                                {productInfo.attributes.user.data.attributes.phone}
                                            </a>
                                        </strong>
                                    </p>
                                    <p className="item-info">
                                        <label>E-mail:</label>
                                        <strong>
                                            <a href={`mailto:${productInfo.attributes.user.data.attributes.email}`}>
                                                {productInfo.attributes.user.data.attributes.email}
                                            </a>
                                        </strong>
                                    </p>
                                    <p className="item-info">
                                        <label>Lokalizacja:</label>
                                        <strong>
                                            {productInfo.attributes.voivodeship.data.attributes.label
                                            + ', ' + productInfo.attributes.city}
                                        </strong>
                                    </p>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card className="product-single-spec" title="Dane techniczne" bordered={false}>
                                    <p className="item-info">
                                        <label>Producent:</label>
                                        <strong>{productInfo.attributes.producer}</strong>
                                    </p>
                                    <p className="item-info">
                                        <label>Rodzaj:</label>
                                        <strong>{productInfo.attributes.category.data.attributes.name}</strong>
                                    </p>
                                    <p className="item-info">
                                        <label>Model:</label>
                                        <strong>{productInfo.attributes.model}</strong>
                                    </p>
                                    <p className="item-info">
                                        <label>Rok produkcji:</label>
                                        <strong>{productInfo.attributes.year}</strong>
                                    </p>
                                    <p className="item-info">
                                        <label>Stan:</label>
                                        <strong>{productInfo.attributes.condition}</strong>
                                    </p>
                                </Card>
                            </Col>
                        </Row>
                    </Affix>
                </Col>
            </Row>
        </div>
    );
});

export default ProductSingle;