import {useContext} from "react";
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'


import {Context} from "../index";
import {authRoutes, publicRoutes} from "../routes";
import NotFound from "../pages/NotFound/NotFound";
import Layout from "./Layout";
import {ACCOUNT_ROUTE, LOGIN_ROUTE, REGISTRATION_ROUTE, SEARCH_RESULT_ROUTE} from "../utils/consts";


const AppRouter = () => {
    const {user} = useContext(Context)
    const location = useLocation()

    return (
        <Routes>
            <Route path='/' element={<Layout />}>
                {user.isAuth && authRoutes.map(({path, Page}) =>
                    <Route key={path} path={path} element={Page}/>
                )}
                {publicRoutes.map(({path, Page}) =>
                    <Route key={path} path={path} element={Page}/>
                )}

                <Route
                    exact
                    path={ACCOUNT_ROUTE}
                    element={(<Navigate to={LOGIN_ROUTE} replace />)}
                />
                <Route path="*" element={<NotFound/>}/>
                {location.pathname.match('/.*/$') && !location.search ?
                    <Route
                        path={location.pathname}
                        element={
                            <Navigate replace to={{
                                pathname: location.pathname.replace(/\/+$/, ""),
                                search: location.search
                        }}/>}
                    />
                    :
                    ''
                }
                {user.isAuth ?
                    <>
                        <Route
                            path={`${REGISTRATION_ROUTE}/`}
                            element={<Navigate to={ACCOUNT_ROUTE} replace />}
                        />
                        <Route
                            path={`${LOGIN_ROUTE}/`}
                            element={<Navigate to={ACCOUNT_ROUTE} replace />}
                        />
                    </>
                    :
                    ''
                }

                <Route
                    path="/szukaj/*"
                    element={<Navigate to={SEARCH_RESULT_ROUTE + '/' + location.pathname.split('/')[2]} replace />}
                />
                <Route
                    path="/szukaj"
                    element={<Navigate to={SEARCH_RESULT_ROUTE + '/'} replace />}
                />
            </Route>
        </Routes>
    );
};

export default AppRouter;