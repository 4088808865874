import {
    ABPLANALP_SHOP_ROUTE, ACCOUNT_ROUTE, ACCOUNT_ROUTE_EDIT_PRODUCT, ACCOUNT_ROUTE_MAIN, AGREEMENT_ROUTE,
    BLOG_ROUTE, CONTACT_ROUTE, EMAIL_CONFIRMATION_ROUTE, FAQ_ROUTE, FORGOT_PASSWORD_ROUTE, HOME_ROUTE,
    LOGIN_ROUTE, POLICY_COOKIES_ROUTE, POLICY_PRIVACY_ROUTE, PRICES_ROUTE, REGISTRATION_ROUTE, RESET_PASSWORD_ROUTE,
    SEARCH_RESULT_ROUTE, SHOP_ROUTE
} from "./utils/consts";
import Account from "./pages/Account/Account";
import Home from "./pages/Home/Home";
import Auth from "./pages/Auth/Auth";
import Contact from "./pages/Contact/Contact";
import ResetPassword from "./pages/Auth/ResetPassword";
import EmailConfirmation from "./pages/Auth/EmailConfirmation";
import ProductSingle from "./pages/ProductSingle/ProductSingle";
import SearchResult from "./pages/SearchResults/SearchResults";
import AbplanalpProducts from "./pages/AbplanalpProducts/AbplanalpProducts";
import Faq from "./pages/Faq/Faq";
import Prices from "./pages/Prices/Prices";
import Blog from "./pages/Blog/Blog";
import BlogSingle from "./pages/Blog/BlogSingle";
import PolicyPrivacy from "./pages/PolicyPrivacy/PolicyPrivacy";
import Agreement from "./pages/Agreement/Agreement";
import PolicyCookies from "./pages/PolicyCookies/PolicyCookies";

export const authRoutes = [
    {
        path: ACCOUNT_ROUTE,
        Page: <Account/>
    },
    {
        path: ACCOUNT_ROUTE_MAIN + '/*',
        Page: <Account/>
    },
    {
        path: ACCOUNT_ROUTE_EDIT_PRODUCT + '/:id',
        Page: <Account/>
    }

]

export const publicRoutes = [

    {
        path: HOME_ROUTE,
        Page: <Home/>
    },
    {
        path: SHOP_ROUTE + '/:id',
        Page: <ProductSingle/>
    },
    {
        path: LOGIN_ROUTE,
        Page: <Auth/>
    },
    {
        path: REGISTRATION_ROUTE,
        Page: <Auth/>
    },
    {
        path: FORGOT_PASSWORD_ROUTE,
        Page: <ResetPassword/>
    },
    {
        path: RESET_PASSWORD_ROUTE,
        Page: <ResetPassword/>
    },
    {
        path: EMAIL_CONFIRMATION_ROUTE,
        Page: <EmailConfirmation/>
    },
    {
        path: CONTACT_ROUTE,
        Page: <Contact/>
    },
    {
        path: SEARCH_RESULT_ROUTE + '/*',
        Page: <SearchResult/>
    },
    {
        path: ABPLANALP_SHOP_ROUTE,
        Page: <AbplanalpProducts/>
    },
    {
        path: FAQ_ROUTE,
        Page: <Faq/>
    },
    {
        path: PRICES_ROUTE,
        Page: <Prices/>
    },
    {
        path: BLOG_ROUTE,
        Page: <Blog/>
    },
    {
        path: BLOG_ROUTE + '/:id',
        Page: <BlogSingle/>
    },
    {
        path: POLICY_PRIVACY_ROUTE,
        Page: <PolicyPrivacy/>
    },
    {
        path: AGREEMENT_ROUTE,
        Page: <Agreement/>
    },
    {
        path: POLICY_COOKIES_ROUTE,
        Page: <PolicyCookies/>
    }
]