export const HOME_ROUTE = '/'

export const ACCOUNT_ROUTE_MAIN = '/konto'
export const ACCOUNT_ROUTE = '/konto/moje-konto'
export const ACCOUNT_ROUTE_MY_PRODUCTS = '/konto/moje-ogloszenia'
export const ACCOUNT_ROUTE_ADD_PRODUCT = '/konto/dodaj-ogloszenie'
export const ACCOUNT_ROUTE_EDIT_PRODUCT = '/konto/produkt'
export const ACCOUNT_ROUTE_FAVOURITE = '/konto/obserwowane'
export const ACCOUNT_ROUTE_PACKAGES = '/konto/pakiety-ogloszen'

export const LOGIN_ROUTE = '/logowanie'
export const REGISTRATION_ROUTE = '/rejestracja'
export const FORGOT_PASSWORD_ROUTE = '/resetowanie-hasla'
export const RESET_PASSWORD_ROUTE = '/ustalanie-nowego-hasla'
export const EMAIL_CONFIRMATION_ROUTE = '/weryfikacja-email'

export const ABPLANALP_SHOP_ROUTE = 'https://sklep.obrabiarka.pl'
export const BLOG_ROUTE = '/blog'
export const PRICES_ROUTE = '/cennik'
export const CONTACT_ROUTE = '/kontakt'
export const ABOUT_ROUTE = '/o-nas'

export const SHOP_ROUTE = '/oferta'

export const FAQ_ROUTE = '/faq'
export const POLICY_PRIVACY_ROUTE = '/polityka-prywatnosci'
export const AGREEMENT_ROUTE = '/regulamin'
export const POLICY_COOKIES_ROUTE = '/polityka-cookies'

export const REDIRECT_PAYMENT_ROUTE = 'https://secure.przelewy24.pl/trnRequest/'
export const MAIN_PAYMENT_ROUTE = '/konto/status'


export const SEARCH_RESULT_ROUTE = '/kategoria'