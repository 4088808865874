import React, {useContext, useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {
    InstantSearch, SearchBox, RefinementList, Configure, Panel, HierarchicalMenu,
    Breadcrumb, ClearRefinements, connectStateResults,
} from 'react-instantsearch-dom';
import {instantMeiliSearch} from "@meilisearch/instant-meilisearch";
import qs from 'qs';
import {Button, Col, Row, Space, Spin} from "antd";

import {Context} from "../../index";
import CustomHits from "./Hits";
import FiltersCustom from "./FiltersCustom";
import {ACCOUNT_ROUTE, ACCOUNT_ROUTE_ADD_PRODUCT, HOME_ROUTE, SEARCH_RESULT_ROUTE} from "../../utils/consts";
import {Helmet} from "react-helmet";
import BreadcrumbCustom from "./BreadcrumbCustom";

import './style.less'



const SearchResults = observer(() => {
    const {global, user} = useContext(Context)
    const location = useLocation()
    const navigate = useNavigate()
    const [isNoHits, setIsNoHits] = useState(0)
    const [title, setTitle] = useState('')
    const [metaDescription, setMetaDescription] = useState('')
    const [metaTitle, setMetaTitle] = useState('')


    const searchClient = instantMeiliSearch(
        process.env.REACT_APP_MEILISEARCH_URL,
        process.env.REACT_APP_MEILISEARCH_API_KEY,
        {
            primaryKey: 'id',
            keepZeroFacets: false
        }
    )
    const decodedCategories = Object.keys(global.encCategories).reduce((acc, key) => {
        const newKey = global.encCategories[key];
        const newValue = key;
        return {
            ...acc,
            [newKey]: newValue,
        };
    }, {});

    function getCategorySlug(name) {
        const encodedName = decodedCategories[name] || name;
        return encodedName
            .split('+')
            .map(encodeURIComponent)
            .join('');
    }

    function getCategoryName(slug) {
        const decodedSlug = global.encCategories[slug] || slug;
        return decodedSlug
            .split('+')
            .map(decodeURIComponent)
            .join(' ');
    }

    const createURL = state => {
        const isDefaultRoute =
            !state.query &&
            state.page === 1 &&
            (state.refinementList && state.refinementList.voivodeship.length === 0) &&
            (state.menu && !state.menu.categories);

        if (isDefaultRoute) {
            return '';
        }
        const categoryPath = state.hierarchicalMenu.category
            ? `${getCategorySlug(state.hierarchicalMenu.category)}/`
            : '';

        const queryParameters = {};

        if (state.query) {
            queryParameters.query = encodeURIComponent(state.query);
        }
        if (state.page !== 1) {
            queryParameters.page = state.page;
        }
        if (state.refinementList.voivodeship) {
            queryParameters.voivodeship = state.refinementList.voivodeship.map(encodeURIComponent);
        }

        const queryString = qs.stringify(queryParameters, {
            addQueryPrefix: true,
            arrayFormat: 'repeat',
        });
        return `${SEARCH_RESULT_ROUTE}/${categoryPath}${queryString}`;
    };
    const searchStateToUrl = searchState => {
        return searchState ? createURL(searchState) : '';
    }
    const urlToSearchState = location => {
        const pathnameMatches = location.pathname.match(/kategoria\/(.*?)\/?$/);
        const category = getCategoryName(
            (pathnameMatches && pathnameMatches[1]) || ''
        );

        const {query = '', page = 1, voivodeship = []} = qs.parse(location.search.slice(1));
        const allVoivodeships = Array.isArray(voivodeship) ? voivodeship : [voivodeship].filter(Boolean);

        return {
            query: decodeURIComponent(query),
            page,
            hierarchicalMenu: {
                category: decodeURIComponent(category),
            },
            refinementList: {
                voivodeship: allVoivodeships.map(decodeURIComponent),
            },
        };
    };

    const debouncedSetStateRef = useRef(null);

    const onSearchStateChange = updatedSearchState => {
        clearTimeout(debouncedSetStateRef.current);
        debouncedSetStateRef.current = setTimeout(() => {
            navigate(searchStateToUrl(updatedSearchState), updatedSearchState);
        }, 0);
    };


    const LoadingIndicator = connectStateResults(({isSearchStalled}) =>
        isSearchStalled ? <Spin size="large" className="spin-main"/> : null
    )





    const Results = connectStateResults(
        ({ searchState, searchResults, children }) =>
            searchResults && searchResults.nbHits !== 0 ? (
                children
            ) : (

                <div className="no-result-wrapper">
                    <p>Nie znaleziono wyników wyszukiwania. <Link to={SEARCH_RESULT_ROUTE}>Zobacz wszystkie ogłoszenia</Link></p>
                    <Space>
                        <Link to={user.isAuth ? ACCOUNT_ROUTE_ADD_PRODUCT : ACCOUNT_ROUTE}>
                            <Button style={{display: "block"}}
                                    type="primary">
                                Dodaj ogłoszenie
                            </Button>
                        </Link>
                    </Space>
                </div>
            )
    )

    const hitCategoryDescriptions = name => {
        let hitCategoryDesc;
        global.categories.map((cat) => {
            let subCategoryName = cat.attributes.subCategories.data
            if (cat.attributes.name === name) {
                hitCategoryDesc = cat.attributes.description
            } else if (name === '') {
                //console.log('')
            } else {
                subCategoryName.map((sub) => {
                    let convertSubCategory = cat.attributes.name + " > " + sub.attributes.name
                    if (convertSubCategory === name) {
                        hitCategoryDesc = sub.attributes.description
                    }
                    return hitCategoryDesc
                })
            }
        })
        return hitCategoryDesc;
    }

    useEffect(() => {
        let htmlDescription = hitCategoryDescriptions(urlToSearchState(location).hierarchicalMenu.category)
        if(htmlDescription) {
            let mainTitle = htmlDescription.match(/<ins>(.*?)<\/ins>/g)?.map((a) => a.replace(/<ins>|<\/ins>/g, ""));
            let title = htmlDescription.match(/<sub>(.*?)<\/sub>/g)?.map((a) => a.replace(/<sub>|<\/sub>/g, ""));
            let description = htmlDescription.match(/<sup>(.*?)<\/sup>/g)?.map((a) => a.replace(/<sup>|<\/sup>/g, ""));
            setMetaTitle(title)
            setMetaDescription(description)

            if(mainTitle) {
                setTitle(mainTitle)
            } else {
                setTitle(urlToSearchState(location).hierarchicalMenu.category.substr(urlToSearchState(location)
                    .hierarchicalMenu.category.indexOf(">") + 1))
            }
        } else {
            setTitle(urlToSearchState(location).hierarchicalMenu.category.substr(urlToSearchState(location)
                .hierarchicalMenu.category.indexOf(">") + 1))
            setMetaTitle('Giełda maszyn CNC')
            setMetaDescription('Ogłoszenia - maszyny i narzędzia CNC')
        }
    }, [hitCategoryDescriptions(urlToSearchState(location).hierarchicalMenu.category)], metaTitle)

    return (
        <div className="page-wrapper search-result-wrapper">
            <Helmet>
                <title>{metaTitle + ' | Obrabiarka.pl'}</title>
                <meta
                    name="description"
                    content={metaDescription}
                />
            </Helmet>
            <InstantSearch
                searchClient={searchClient}
                indexName="product"
                searchState={urlToSearchState(location)}
                onSearchStateChange={onSearchStateChange}
                createURL={createURL}
            >
                <LoadingIndicator/>
                <Configure distinct={1}/>
                <div className="hide-input-search">
                    <SearchBox/>
                </div>

                <Row className="m-0" gutter={[0, 16]}>
                    <Col span={24} lg={6} className="filters-wrapper">
                        <FiltersCustom>
                            <div className="aff-wrapper">
                                <div className="filters-header">
                                    <ClearRefinements
                                        clearsQuery
                                        translations={{
                                            reset: 'Wyczyść wszystkie',
                                        }}
                                    />
                                </div>
                                <Panel header="Kategorie">
                                    <div className="return-prev-category">
                                        <BreadcrumbCustom
                                            attributes={[
                                                "category",
                                            ]}
                                        />
                                    </div>
                                    <HierarchicalMenu
                                        showParentLevel={false}
                                        attributes={[
                                            "category",
                                            "subCategory"
                                        ]}
                                    />
                                </Panel>
                                <Panel header="Lokalizacja">
                                    <RefinementList
                                        attribute="voivodeship"
                                    />
                                </Panel>
                            </div>
                        </FiltersCustom>
                    </Col>
                    <Col span={24} lg={18} className="result-wrapper">
                        <Row className="custom-breadcrumbs">
                            <h1 className="title-main">
                                {urlToSearchState(location).hierarchicalMenu.category ?
                                    title : 'Wszystkie produkty'
                                }
                            </h1>
                            {isNoHits !== 0 ?
                                <Link to={HOME_ROUTE} className="breadcrumb-home-text">
                                    Strona główna <span>/</span>
                                </Link> : ''

                            }
                            <Breadcrumb
                                attributes={[
                                    "category",
                                    "subCategory"
                                ]}
                                translations={{
                                    rootLabel: 'Wszystkie kategorie',
                                }}
                                separator={<span>/</span>}

                                transformItems={items => {
                                    setIsNoHits(items.length)
                                  return items.map(item => ({
                                        ...item
                                        }))
                                }}
                            />

                        </Row>
                        <Configure hitsPerPage={10}/>
                        <Row gutter={[0, 16]} className="custom-hits margin-lr">
                            <Results>
                            <CustomHits
                                minHitsPerPage={16}
                                searchStateConditional={urlToSearchState(location).hierarchicalMenu.category}
                            />
                            </Results>
                        </Row>
                        {urlToSearchState(location).hierarchicalMenu.category ?
                            <div className="custom-desc" dangerouslySetInnerHTML={
                                {__html: hitCategoryDescriptions(urlToSearchState(location).hierarchicalMenu.category)}
                            }/>
                        :
                            ''
                        }
                    </Col>

                </Row>

            </InstantSearch>

        </div>
    );
});

export default SearchResults;



