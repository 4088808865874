import React from "react";
import {Link} from "react-router-dom";
import {Col, Row} from "antd";

import {
    ABPLANALP_SHOP_ROUTE, AGREEMENT_ROUTE, BLOG_ROUTE, CONTACT_ROUTE, FAQ_ROUTE, POLICY_COOKIES_ROUTE,
    POLICY_PRIVACY_ROUTE, PRICES_ROUTE
} from "../../utils/consts";


import './Footer.less'
import mailIcon from '../../assets/icons/mail.svg'
import fbIcon from '../../assets/icons/fb.png'

const Footer = ({template}) => {
    const styleGH = {
        textAlign: 'center',
        width: '100%',
        fontSize: '12px',
        padding: '10px 0'
    }
    return (
        <>
            <div className="container-inner footer-main">
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <div className="footer-logo-wrapper">
                            <img
                                width="100%"
                                src={template.logo.data.attributes.url}
                                alt="Obrabiarka.pl – giełda maszyn CNC"
                            />
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <div className='links'>
                            <a href={ABPLANALP_SHOP_ROUTE}>Sklep z narzędziami</a>
                            <Link to={BLOG_ROUTE}>Blog</Link>
                            <Link to={PRICES_ROUTE}>Cennik</Link>
                            <Link to={CONTACT_ROUTE}>Kontakt</Link>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <div className='links'>
                            <Link to={FAQ_ROUTE}>Najczęściej zadawane pytania</Link>
                            <Link to={POLICY_PRIVACY_ROUTE} rel="nofollow">Polityka prywatności</Link>
                            <Link to={AGREEMENT_ROUTE} rel="nofollow">Regulamin</Link>
                            <Link to={POLICY_COOKIES_ROUTE} rel="nofollow">Polityka cookies</Link>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <div className="support-wrapper">
                            <img src={mailIcon} alt="support"/>
                            <div className="support-info">
                                <p>Masz pytania?<br/> Skontaktuj się z nami!</p>
                                <a href={`mailto:${template.email}`}>{template.email}</a>
                                <div className="fb-wrapper">
                                    <a href="https://www.facebook.com/obrabiarki.cnc" target="_blank">
                                        <p>Odwiedź nas</p>
                                        <img src={fbIcon} alt="facebook"/>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>
            <p style={styleGH}>
                Projekt i realizacja
                <a style={{color: '#333', paddingLeft:'5px'}} href="https://grizzlyheart.pl" target='_blank'>
                    <strong> GrizzlyHeart.pl</strong>
                </a>
            </p>
        </>
    )
}

export default Footer;