import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Col, Row, Spin, Table} from "antd";

import {fetchPricesPage} from "../../http/pages/pricesAPI";

import './style.less'


const Prices = () => {
    const [htmlPrices, setHTMLPrices] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchPricesPage().then(data => {
            if (data.attributes) {
                return (
                    setHTMLPrices({
                        ...data.attributes,
                        promo: {__html: data.attributes.promo},
                    })
                )
            }
        }).finally(() => setLoading(false))
    }, [])
    if (loading) {
        return <Spin animation={"grow"}/>
    }

    return (
        <div className="page-wrapper prices-wrapper">
            <Helmet>
                <title>obrabiarka.pl - cennik ogłoszeń</title>
                <meta name="description" content="Ogłoszenia - maszyny i narzędzia CNC"/>
            </Helmet>
            <Row gutter={[0, 32]}>
                <Col span={24}>
                    <h1 className="title-main">
                        Cennik
                    </h1>
                    <div
                        className="contact-info-wrapper"
                        dangerouslySetInnerHTML={htmlPrices.promo}/>
                </Col>
                <Col span={24}>
                    <div className="prices-table-wrapper">
                        <h3>{htmlPrices.tablePricesAddTitle}</h3>
                        <Table
                            pagination={false}
                            dataSource={htmlPrices.tablePricesAddBody}
                            columns={htmlPrices.tablePricesAddHeader}
                            scroll={{
                                x: 1300,
                            }}
                        />
                    </div>
                </Col>
                <Col span={24}>
                    <div className="promo-table-wrapper">
                        <h3>{htmlPrices.tablePromoTitle}</h3>
                        <Table
                            pagination={false}
                            dataSource={htmlPrices.tablePromoBody}
                            columns={htmlPrices.tablePromoHeader}
                            scroll={{
                                x: 1300,
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Prices;