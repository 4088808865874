import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../../index";

import './style.less'


const AddToFav = ({item, itemConvert}) => {
    const {user} = useContext(Context)
    const [currentFavNew, setCurrentFavNew] = useState({})

    useEffect(() => {
        const productsFav = JSON.parse(
            localStorage.getItem('fav')
        );
        if (productsFav) {
            user.setUserFav(productsFav);
        }
        if (item) {
            setCurrentFavNew({
                id: item.attributes ? item.id : item.numberId,
                title: item.attributes ? item.attributes.title : item.title,
                imageUrl: item.attributes ? item.attributes.gallery.data[0].attributes.url
                    : item.gallery ? item.gallery[0].url : '',
                price: item.attributes ? item.attributes.price : item.price,
                slug: item.attributes ? item.attributes.slug : item.slug,
                withoutPrice: item.attributes ? item.attributes.withoutPrice : item.withoutPrice
            })
        } else {
            setCurrentFavNew(itemConvert)
        }
    }, [])
    const saveToLocalStorage = (items) => {
        localStorage.setItem('fav', JSON.stringify(items));
    }
    const productAlreadyExist = (productId, favorites) => {
        return !!favorites.find((prod) => prod.id === productId)
    }
    const handleFavouritesClick = (currentFav) => {
        if (productAlreadyExist(currentFav.id, user.userFav)) {
            const newFavouriteList = user.userFav.filter(
                (favourite) => favourite.id !== currentFav.id
            )
            user.setUserFav(newFavouriteList)
            saveToLocalStorage(newFavouriteList)
        } else {
            user.setUserFav([...user.userFav, currentFav])
            saveToLocalStorage(user.userFav)
        }
    }
    return (
        <button
            onClick={() => handleFavouritesClick(currentFavNew)}
            className={`button-fav ${user.userFav.find(el => el.id === currentFavNew.id) ? "fill" : "empty"}`}
        />
    );
};

export default AddToFav;