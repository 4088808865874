import React from "react";
import {NavLink} from "react-router-dom";
import {Col, Row, Statistic, Typography} from "antd";

import {SHOP_ROUTE} from "../../utils/consts";
import AddToFav from "../../components/UI/AddToFav/AddToFav";
import {Helmet} from "react-helmet";


const Favorites = ({productsFav}) => {
    const {Paragraph} = Typography;

    return (
        <div className="page-container add-to-fav-wrapper">
            <Helmet>
                <title>Obserwowane ogłoszenia</title>
            </Helmet>
            <Row gutter={[16, 16]} className="m-0">
                {productsFav.length ?
                    productsFav.map((item, key) => {
                            return (
                                <Col span={24} sm={12} lg={8} xl={6} key={key}>
                                    <div className="card-container">
                                        <div className="add-to-fav-wrapper">
                                            <NavLink to={SHOP_ROUTE + `/${item.slug}`}>
                                                <div className="image-wrapper">
                                                    <img
                                                        width="100%"
                                                        src={item.imageUrl}
                                                        alt="product"
                                                    />
                                                </div>
                                                <div className="content-wrapper">
                                                    <Paragraph className="card-title" ellipsis={{rows: 2}}>
                                                        {item.title}
                                                    </Paragraph>
                                                    {item.withoutPrice ?
                                                        <p className="card-without-price">
                                                            Cena do uzgodnienia
                                                        </p>
                                                        :
                                                        <div className="card-price">
                                                            <Statistic value={item.price} precision={2} suffix="zł"/>
                                                            {item.isVat ? <span className="with-vat">+ VAT</span> : ''}
                                                        </div>
                                                    }
                                                </div>
                                            </NavLink>
                                            <AddToFav itemConvert={item}/>
                                        </div>
                                    </div>
                                </Col>
                            )
                        }
                    )
                    :
                    <div>Brak obserwowanych ogłoszeń</div>
                }
            </Row>
        </div>

    );
};

export default Favorites;