import React from 'react';
import {Link} from "react-router-dom";
import {Carousel, Col, Row, Typography} from "antd";

import {SEARCH_RESULT_ROUTE} from "../../../utils/consts";

import aarDouble from '../../../assets/icons/arrDouble.svg'


const CategorySlider = ({cat}) => {
    const {Paragraph} = Typography;
    return (
        <>
            <Carousel
                dots={false}
                draggable={true}
                slidesToShow={3}
                arrows={true}
                initialSlide={0}
                responsive={[
                    {
                        breakpoint: 1250,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 1100,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 1000,
                        settings: {
                            slidesToShow: 1,

                        }
                    }
                ]}
            >
                {
                    cat.map((card, key) => {
                            let url = card.attributes.image.data.attributes.url;
                            let altText = card.attributes.image.data.attributes.alternativeText;
                            return (
                                <div key={key} className="slide-box-wrapper">
                                    <Row className="slide-box">
                                        <Col span={9} xs={6} className="slide-image-wrapper">
                                            <div className="slide-cat-image">
                                                <img className='hero-image' src={url} alt={altText}/>
                                            </div>
                                        </Col>
                                        <Col span={15} xs={18} className="slide-cat-info">
                                            <h3>{card.attributes.name}</h3>
                                            <Paragraph className="card-desc" ellipsis={{rows: 4}}>
                                                {card.attributes.shortDescription}
                                            </Paragraph>
                                            <Link
                                                className="slide-link-cat"
                                                to={SEARCH_RESULT_ROUTE + '/' + card.attributes.slug}
                                            >
                                                <span>Zobacz wszystkie</span>
                                                <img src={aarDouble} width="100%" alt="arr"/>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
                    )
                }
            </Carousel>
        </>
    );
};

export default CategorySlider;