import React, {useEffect, useState} from 'react';
import {Carousel} from "antd";

import {fetchHomeHero} from "../../../http/pages/homeAPI";

const HeroSlider = ({heroMobileSlug, heroMobile}) => {
    const [heroSlides, setHeroSlides] = useState([])

    useEffect(() => {
        fetchHomeHero().then(data => data.attributes.hero ? setHeroSlides(data.attributes.hero) : '')
    }, [])

    return (
        <div className="hero-slider-wrapper">
            {window.innerWidth > 720 ?
                <Carousel
                    draggable={true}
                >
                    {
                        heroSlides.map((slide, key) => {
                                let url = slide.image.data.attributes.url;
                                let altText = slide.image.data.attributes.alternativeText;
                                return (
                                    <a href={slide.href} key={key}>
                                        <img className='hero-image' src={url}
                                             alt={altText}/>
                                    </a>
                                )
                            }
                        )
                    }
                </Carousel>
                :
                <a className="hero-mobile" href={heroMobileSlug}>
                    <img className='hero-image' src={heroMobile.data.attributes.url}
                         alt={heroMobile.data.attributes.alternativeText}/>
                </a>
            }
        </div>
    );
};

export default HeroSlider;