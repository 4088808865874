import React, {useEffect, useState} from 'react';
import {Col, Row, Spin, Affix} from "antd";

import {fetchFaqPage} from "../../http/pages/faqAPI";

import './style.less'
import {Helmet} from "react-helmet";


const Faq = () => {
    const [htmlFaq, setHTMLFaq] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchFaqPage().then(data => {
            if (data.attributes) {
                return (
                    setHTMLFaq({
                        menu: data.attributes.menu,
                        body: {__html: data.attributes.body}
                    })
                )
            }
        }).finally(() => setLoading(false))
    }, [])
    if (loading) {
        return <Spin animation={"grow"}/>
    }
    return (
        <div className="page-wrapper faq-wrapper">
            <Helmet>
                <title>obrabiarka.pl - FAQ</title>
            </Helmet>
            <Row gutter={[0, 32]}>
                <Col span={24} lg={18}>
                    <h1 className="title-main">
                        Najczęściej zadawane pytania
                    </h1>
                    <div
                        className="faq-info-wrapper"
                        dangerouslySetInnerHTML={htmlFaq.body}/>
                </Col>
                <Col span={0} lg={6}>
                    <Affix offsetTop={35}>
                    <div className="faq-menu-wrapper">
                        {htmlFaq.menu.map((link) => {
                            return <div key={link.id} className="link-wrapper"><a href={link.slug}>{link.label}</a></div>
                        })}
                    </div>
                    </Affix>
                </Col>
            </Row>
        </div>
    );
};

export default Faq;