import React, {useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {Col, Modal, Row, Tooltip, Typography} from "antd";

import {ACCOUNT_ROUTE_EDIT_PRODUCT, SHOP_ROUTE} from "../../utils/consts";

import editIcon from '../../assets/icons/edit.svg'
import linkIcon from '../../assets/icons/link.svg'
import deleteIcon from '../../assets/icons/delete.svg'
import {Helmet} from "react-helmet";
import {deleteUserProductAd, getSingleUserProductAd, updateUserProductAd} from "../../http/productAdAPI";


const ProductList = ({productsAd}) => {
    const navigate = useNavigate();
    const {Paragraph} = Typography;
    const { confirm } = Modal;

    const showConfirm = async (id) => {
         confirm({
            title: 'Czy na pewno chcesz usunąć to ogłoszenie?',
            content: 'Nie da się przywrócić usuniętego ogłoszenia',
             okText: 'Tak',
             cancelText: 'Anuluj',
             onOk() {
                try {
                    getSingleUserProductAd(id).then((data) => {
                         updateUserProductAd(id, {publishedAt: null})
                    }).finally(() => setTimeout(() => {navigate(0)},500))
                    // ?publicationState=live
                     // deleteUserProductAd(id, userProductAd).then((data) => {}).finally(() => navigate(0))
                } catch (e) {
                    console.log('error', e.message)
                }

            },
            onCancel() {},
        });
    };

    return (
        <div className="page-container product-list-ad">
            <Helmet>
                <title>Moje ogłoszenia</title>
            </Helmet>
            <Row gutter={[16, 16]} className="m-0">
                {productsAd.userProductsAd.length ?
                    productsAd.userProductsAd.map((item, key) => {
                            return (
                                <Col span={24} md={8} lg={6} key={key}>
                                    <div className="card-container card-edit-container">
                                        <div className="image-wrapper">
                                            <img
                                                width="100%"
                                                src={item.gallery ? item.gallery[0].url : ''}
                                                alt={item.gallery ? item.gallery[0].alternativeText : 'image'}
                                            />
                                        </div>
                                        <div className="card-content-wrapper">
                                            <Paragraph className="card-title" ellipsis={{rows: 2}}>
                                                {item.title}
                                            </Paragraph>
                                            <p className="card-price">{item.price} zł</p>
                                        </div>
                                        <div className="edit-prod">
                                            <NavLink to={ACCOUNT_ROUTE_EDIT_PRODUCT + `/${item.id}`}>
                                                <Tooltip placement="bottom" title="Edytuj">
                                                    <div className="icon-wrapper edit">
                                                        <img src={editIcon} alt="edit"/>
                                                    </div>
                                                </Tooltip>

                                            </NavLink>
                                            <NavLink to={SHOP_ROUTE + `/${item.slug}`}
                                                     target="_blank"
                                                     rel="noopener noreferrer"
                                            >
                                                <Tooltip placement="bottom" title="Podgląd">
                                                    <div className="icon-wrapper link-to">
                                                        <img src={linkIcon} alt="link-to"/>
                                                    </div>
                                                </Tooltip>
                                            </NavLink>
                                            <button
                                                onClick={() => showConfirm(item.id)}
                                            >
                                                <div className="icon-wrapper link-to">
                                                    <img src={deleteIcon} alt="delete-icon"/>
                                                </div>
                                            </button>
                                        </div>
                                    </div>

                                </Col>
                            )
                        }
                    )
                    :
                    <div>Nie masz jeszcze żadnego ogłoszenia</div>
                }
            </Row>
        </div>
    );
};

export default ProductList;