import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Col, Row, Spin} from "antd";

import {fetchPolicyPrivacyPage} from "../../http/pages/agreementPagesAPI";

import './style.less'


const PolicyPrivacy = () => {
    const [htmlPolicyPrivacy, setHTMLPolicyPrivacy] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchPolicyPrivacyPage().then(data => setHTMLPolicyPrivacy(data.attributes))
            .finally(() => setLoading(false))
    }, [])

    if (loading) {
        return <Spin animation={"grow"}/>
    }
    return (
        <div className="page-wrapper privacy-wrapper">
            <Helmet>
                <title>{htmlPolicyPrivacy.title}</title>
            </Helmet>
            <Row gutter={[16, 0]} className="margin-lr">
                <Col span={24}>
                    <h1 className="title-main">
                        {htmlPolicyPrivacy.title}
                    </h1>
                </Col>
                <Col span={24} className="privacy-info-wrapper">
                    <div dangerouslySetInnerHTML={{__html: htmlPolicyPrivacy.bodyHTML}}/>
                </Col>
            </Row>
        </div>

    );
};
export default PolicyPrivacy;