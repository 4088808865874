import {$authHost, $authHostPrzelewy24} from "./index";

// export const testTransaction = async () => {
//     const {data} = await $authHostPrzelewy24.get('/api/v1/testAccess')
//     return data.data
// }
//
//

export const registerTransaction = async (payload) => {
    const {data} = await $authHostPrzelewy24.post('/api/v1/transaction/register', payload)
    return data.data
}

export const notificationTransaction = async (sID) => {
    const {data} = await $authHostPrzelewy24.get(`/api/v1/transaction/by/sessionId/${sID}`)
    return data.data
}

export const verifyTransaction = async (payload) => {
    const {data} = await $authHostPrzelewy24.put(`/api/v1/transaction/verify`,payload)
    return data.data
}

export const fetchPaymentsCard = async () => {
    const {data} = await $authHost.get('/payments?populate=*&sort=id')
    return data.data
}

export const fetchOrders = async () => {
    const {data} = await $authHost.get('/orders?populate=*')
    return data.data
}

export const createOrder = async (order) => {
    const {data} = await $authHost.post('/orders', {data:{...order}})
    return data.data
}

export const updateOrder = async (id) => {
    const {data} = await $authHost.put(`/orders/${id}`)
    return data.data
}

export const getUserDataOrder = async () => {
    const {data} = await $authHost.get('/users/me?populate=*')
    return data
}