import React, {useContext} from 'react';
import {NavLink} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Col, Row, Statistic, Typography} from "antd";

import {Context} from "../../index";
import {SHOP_ROUTE} from "../../utils/consts";

import './style.less'
import AddToFav from "../UI/AddToFav/AddToFav";


const CardCustomGrid = observer(({item}) => {
    const {user} = useContext(Context)
    const {Paragraph} = Typography;

    return (
        <div className="card-container card-container-grid">
            <Row gutter={[0, 16]}>
                <Col span={24} lg={8} className="image-wrapper">
                    <NavLink to={SHOP_ROUTE + `/${item.slug}`}>
                        <img
                            width="100%"
                            src={item.gallery ? item.gallery[0].url : ''}
                            alt={item.title}
                        />
                    </NavLink>
                </Col>
                <Col span={24} lg={16} className="card-content-wrapper">
                    <NavLink to={SHOP_ROUTE + `/${item.slug}`}>
                        <Paragraph className="card-title" ellipsis={{rows: 2}}>
                            {item.title}
                        </Paragraph>
                        <p className="card-producer">
                            <span>Producent: </span>{item.producer}
                        </p>
                    </NavLink>
                    <Paragraph className="card-description" ellipsis={{rows: 3}}>
                        {item.description}
                    </Paragraph>
                    <div className="bottom-info">
                        <p className="card-location">
                            {item.voivodeship[0]},
                            <span> {item.city}</span>
                        </p>
                        {item.withoutPrice ?
                            <p className="card-without-price">
                                Cena do uzgodnienia
                            </p>
                            :
                            <div className="card-price">
                                <Statistic value={item.price} precision={2} suffix="zł"/>
                                {item.isVat ? <span className="with-vat">+ VAT</span> : ''}
                            </div>
                        }
                        <AddToFav
                            item={item}
                            isActive={user.userFav.find(el =>  el.numberId === item.id)}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
});

export default CardCustomGrid;