import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {Col, Row, Spin} from "antd";

import {fetchBlogOne} from "../../http/pages/blogAPI";

import dateIcon from '../../assets/icons/date.svg'

const BlogSingle = () => {
    const {id} = useParams()
    const [blogCard, setBlogCard] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchBlogOne(id).then(data => setBlogCard(data))
            .finally(() => setLoading(false))

    }, [id])

    if (loading) {
        return <Spin animation={"grow"}/>
    }
    return (
        <div className="page-wrapper blog-single-wrapper">
            <Helmet>
                <title>{blogCard.attributes.title}</title>
                <meta name="description" content="Ogłoszenia - maszyny i narzędzia CNC"/>
            </Helmet>
            <Row gutter={[16, 16]} className="blog-single margin-lr" justify="center">
                <Col span={24} lg={14}>
                    <div className="image-wrapper">
                        <img
                            src={blogCard.attributes.image.data.attributes.url}
                            alt={blogCard.attributes.image.data.attributes.alternativeText}
                        />
                    </div>
                    <h1 className="title-main">
                        {blogCard.attributes.title}
                    </h1>
                    <div className="card-date-container">
                        <img src={dateIcon} alt="date-icon"/>
                        <p className="card-date">
                            {new Date(blogCard.attributes.publishedAt).toLocaleDateString('pl-PL')}
                        </p>
                    </div>
                    <div className="blog-description">
                        <div
                            className="contact-info-wrapper"
                            dangerouslySetInnerHTML={{__html:blogCard.attributes.description }}/>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default BlogSingle;