import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Col, Row, Spin} from "antd";

import {fetchAgreementPage} from "../../http/pages/agreementPagesAPI";

import './style.less'


const Agreement = () => {
    const [htmlAgreement, setHTMLAgreement] = useState({})
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        fetchAgreementPage().then(data => setHTMLAgreement(data.attributes))
            .finally(() => setLoading(false))
    }, [])

    if (loading) {
        return <Spin animation={"grow"}/>
    }
    return (
        <div className="page-wrapper agreement-wrapper">
            <Helmet>
                <title>{htmlAgreement.title}</title>
            </Helmet>
            <Row gutter={[16, 0]} className="margin-lr">
                <Col span={24}>
                    <h1 className="title-main">
                        {htmlAgreement.title}
                    </h1>
                </Col>
                <Col span={24} className="agreement-info-wrapper">
                    <div dangerouslySetInnerHTML={{__html: htmlAgreement.bodyHTML}}/>
                </Col>
            </Row>
        </div>

    );
};

export default Agreement;