import {$host} from "../index";


export const fetchBlogList = async () => {
    const {data} = await $host.get('/articles?populate=*&sort=id')
    return data.data
}
export const fetchBlogOne = async (slug) => {
    const {data} = await $host.get(`slugify/slugs/article/${slug}?populate=*`)
    return data.data
}

