import {$authHost, $host} from "./index";


export const registration = async ({fullName, companyName, nip, phone, email, password, agreementGDPR, agreementPolicy}) => {
    const {data} = await $host.post('/auth/local/register', {
        fullName, companyName, nip, phone, username: email, email, password, agreementGDPR, agreementPolicy
    })
    return data
}

export const login = async (email, password) => {
    const {data} = await $host.post('/auth/local', {identifier: email, password})
    localStorage.setItem('token', data.jwt)
    return data
}

export const forgotPassword = async (email) => {
    const {data} = await $host.post('/auth/forgot-password', {email})
    return data
}

export const resetPassword = async (code, password, passwordConfirmation) => {
    const {data} = await $host.post('/auth/reset-password', {code, password, passwordConfirmation})
    return data
}

export const getUserData = async () => {
    const {data} = await $authHost.get('/users/me')
    localStorage.setItem('user', data.jwt)
    return data
}

export const updateUserData = async (id, newUserData) => {
    const {data} = await $authHost.put(`/users/${id}`, {...newUserData})
    return data
}