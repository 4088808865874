import {makeAutoObservable,toJS} from "mobx";


export default class ProductStore {
    constructor() {
        this._categories = []
        this._products = []
        this._productsPromoHome = []
        makeAutoObservable(this)
    }

    setCategories(categories) {
        this._categories = categories
    }
    setProducts(products) {
        this._products = products
    }
    setProductsPromoHome(products) {
        let productsPromo = []
        products.map((item) => {
            if (item.attributes.isDisplayHome && new Date(item.attributes.endPromo) > new Date()) {
                productsPromo.push(item)
            }
        })
        this._productsPromoHome = productsPromo
    }

    get categories() {
        return toJS(this._categories)
    }
    get products() {
        return toJS(this._products)
    }
    get productsPromoHome() {
        return toJS(this._productsPromoHome)
    }
}