import React, {useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Button, Col, Form, Input, message, Row, Space} from "antd";

import {forgotPassword, resetPassword} from "../../http/userAPI";
import {HOME_ROUTE, LOGIN_ROUTE, RESET_PASSWORD_ROUTE} from "../../utils/consts";

import './style.less'
import {Helmet} from "react-helmet";


const ResetPassword = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const location = useLocation()
    const navigate = useNavigate()
    const isResetPassword = location.pathname === RESET_PASSWORD_ROUTE
    const code = React.useMemo(() => new URLSearchParams(location.search), [location.search]).get("code")


    const resetPasswordSubmit = async () => {
        try {
            let data;
            if (isResetPassword) {
                data = await resetPassword(code, password, passwordConfirmation)
                message.success("Hasło zostało zmienione")
                navigate(LOGIN_ROUTE)
            } else {
                data = await forgotPassword(email)
                message.success(`Wysłaliśmy podtwierdzenie na pocztę ${email}`)
                navigate(HOME_ROUTE)
            }
        } catch (e) {
            console.log("e", e.response.data.error)
        }
    }

    return (
        <div className="page-wrapper authForm-container">
            <Helmet>
                <title>obrabiarka.pl - reset hasła</title>
            </Helmet>
            <Row>
                <Col span={24} className='route-wrapper'>
                    <div className='link-wrapper'>
                        {isResetPassword ?
                            <span className='link active'>Podaj nowe hasło</span>
                            :
                            <span className='link active'>Zapomniałeś hasła?</span>
                        }
                    </div>
                </Col>
            </Row>
            <Row className='form-wrapper'>
                <Col span={24}>
                    <Form
                        name=""
                        layout="vertical"
                        initialValues={{remember: true}}
                        onFinish={resetPasswordSubmit}
                        autoComplete="off"
                    >
                        <div>
                            {isResetPassword ?
                                <>
                                    <p><p>Proszę podać nowe hasło</p></p>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Wpisz hasło.',
                                            },
                                            {
                                                min: 6,
                                                message: 'Hasło powinno składać się minimum z 6 znaków.'
                                            },
                                        ]}
                                        hasFeedback
                                    >
                                        <Input.Password
                                            placeholder='Podaj nowe hasło'
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="confirm"
                                        dependencies={['password']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Proszę, powtórz swoje hasło',
                                            },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject(new Error('Wprowadzone przez Ciebie hasła różnią się od siebie'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password
                                            placeholder='Powtórz nowe hasło'
                                            value={passwordConfirmation}
                                            onChange={e => setPasswordConfirmation(e.target.value)}
                                        />
                                    </Form.Item>
                                </>
                                :
                                <>
                                    <p>Wystarczy, że podasz swój login lub e-mail, a my pomożemy Ci ustawić nowe hasło.</p>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'Podaj poprawny adres email',
                                            },
                                            {
                                                required: true,
                                                message: 'Wpisz adres e-mail',
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder='Email'
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </Form.Item>
                                </>
                            }

                            <Space className='btn-wrapper-center'>
                                <Button style={{display: "block"}} type="primary" htmlType="submit">
                                    Wyślij
                                </Button>
                            </Space>
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default ResetPassword;