import React, {createContext} from 'react';
import {NavLink} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Button, Col, Row, Space} from "antd";

import {Context} from "../../index";
import {HOME_ROUTE} from "../../utils/consts";

import './style.less'
import {Helmet} from "react-helmet";


const EmailConfirmation = observer(() => {
    const {user} = createContext(Context)
    return (
        <div className="page-wrapper authForm-container">
            <Helmet>
                <title>Obrabiarka.pl - Potwierdź e-mail</title>
            </Helmet>
            <Row className='form-wrapper email-confirm'>
                <Col span={24}>
                    <h2>Wysłaliśmy link aktywacyjny na Twój adres email</h2>
                </Col>
                <Col span={24}>
                    <p>
                        Odbierz pocztę elektroniczną, aby sfinalizować proces rejestracji.
                        Gdyby wiadomość nie dotarła, sprawdź folder SPAM lub skontaktuj się z nami info@obrabiarka.pl
                    </p>
                </Col>
                <Col span={24}>
                    <Space className='btn-wrapper-center'>
                        <NavLink className='link' to={HOME_ROUTE}>
                        <Button style={{display: "block"}} type="primary">
                            Wróć na stronę główną
                        </Button>
                        </NavLink>
                    </Space>
                </Col>
            </Row>
        </div>
    );
});

export default EmailConfirmation;