import React, {useState} from 'react';
import {Affix, Button, Drawer, Space} from "antd";
import filterIcon from "../../assets/icons/filter.svg";

const FiltersCustom = ({children}) => {
    const [openDrawerFilters, setOpenDrawerFilters] = useState(false)
    return (
        <div>
            {window.innerWidth > 992 ?
                <Affix className="mobile-hide" offsetTop={10}>
                    {children}
                </Affix>
                :
                <>
                    <button
                        className="mobile-ham"
                        type="primary"
                        onClick={() => setOpenDrawerFilters(true)}
                    >
                        <p>Filtry</p>
                        <img src={filterIcon} alt="list" width="100%"/>
                    </button>
                    <Drawer
                        visible={openDrawerFilters}
                        title="Filtry"
                        placement="right"
                        onClose={() => setOpenDrawerFilters(false)}
                        open={openDrawerFilters}
                        forceRender={true}
                    >
                        {children}
                        <Space className='btn-wrapper thx-btn'>
                            <Button
                                onClick={() => setOpenDrawerFilters(false)}
                                className="ant-btn-primary"
                                type="primary"
                            >
                                Zastosuj
                            </Button>
                        </Space>
                    </Drawer>
                </>
            }
        </div>
    );
};

export default FiltersCustom;