import React, {useContext, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import qs from 'qs';
import {Button, Col, Dropdown, Menu, Row, Space, Input, Drawer, Affix} from "antd";

import {
    ABPLANALP_SHOP_ROUTE, ACCOUNT_ROUTE, ACCOUNT_ROUTE_ADD_PRODUCT, ACCOUNT_ROUTE_FAVOURITE,
    ACCOUNT_ROUTE_MY_PRODUCTS, ACCOUNT_ROUTE_PACKAGES, BLOG_ROUTE, CONTACT_ROUTE,
    HOME_ROUTE, LOGIN_ROUTE, PRICES_ROUTE, REGISTRATION_ROUTE, SEARCH_RESULT_ROUTE,
} from "../../utils/consts";
import {Context} from "../../index";
import {fetchCategories} from "../../http/productAdAPI";
import {fetchVoivodeship} from "../../http/globalAPI";

import './Navbar.less'
import accIcon from '../../assets/icons/acc.svg'
import favIcon from '../../assets/icons/fav.svg'
import loginIcon from '../../assets/icons/log-in.svg'
import registerIcon from '../../assets/icons/register.svg'
import listIcon from '../../assets/icons/list.svg'
import coinsIcon from '../../assets/icons/coins.svg'


const Navbar = observer(({template}) => {
    const {user, global} = useContext(Context)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openDrawerCategory, setOpenDrawerCategory] = useState(false)
    const [query, setName] = useState({});
    const navigate = useNavigate()
    const location = useLocation()
    const isHome = location.pathname === HOME_ROUTE
    const {Search} = Input;
    const logOut = () => {
        user.setUser({})
        user.setIsAuth(false)
        localStorage.removeItem('token')
        navigate(HOME_ROUTE)
    }
    useEffect(() => {
        fetchCategories().then(categories => {
            if (categories) {
                global.setCategories(categories)
            }
            global.setEncCategories(categories)
        })
        fetchVoivodeship().then((voivodeships) => global.setVoivodeships(voivodeships))
    }, [global.setEncCategories])

    const itemsMenu = global.categories.map((item) => {
        let subCategory = item.attributes.subCategories.data
        return {
            key: item.id,
            label: (
                <Link onClick={() => setOpenDrawer(false)}
                      to={`${SEARCH_RESULT_ROUTE}/${item.attributes.slug}`}
                      title={item.attributes.name}
                >
                    {item.attributes.name}
                </Link>
            ),
            children: subCategory.length ? subCategory.sort((a, b) => a.attributes.order - b.attributes.order).map((sub) => {
                return {
                    key: `${item.id}-${sub.id}`,
                    label: (
                        <Link
                            onClick={() => setOpenDrawer(false)}
                            to={`${SEARCH_RESULT_ROUTE}/${item.attributes.slug}-${sub.attributes.slug}`}
                            title={sub.attributes.name}
                        >
                            {sub.attributes.name}
                        </Link>
                    ),
                }
            }) : [],
        };
    });

    const handleChange = (evt) => {
        const target = evt.target;

        setName({
            ...query,
            [target.name]: target.type === 'checkbox' ? target.checked : target.value,
        });
    };
    const handleSubmit = (value) => {
        navigate(`${SEARCH_RESULT_ROUTE}/?` + qs.stringify(value));
    };

    const items = [
        {
            label: (<Link to={ACCOUNT_ROUTE}>Moje konto</Link>),
            key: '1',
        },
        {
            label: (
                <Link to={ACCOUNT_ROUTE_MY_PRODUCTS}>
                    Moje ogłoszenia
                </Link>
            ),
            key: '2',
        },
        {
            label: (
                <Link to={ACCOUNT_ROUTE_ADD_PRODUCT}>
                    Dodaj ogłoszenie
                </Link>
            ),
            key: '3',
        },
        {
            label: (
                <Link to={ACCOUNT_ROUTE_FAVOURITE}>
                    Obserwowane
                </Link>
            ),
            key: '4',
        },
        {
            label: (
                <Link to={ACCOUNT_ROUTE_PACKAGES}>
                    Doładuj konto
                </Link>
            ),
            key: '5',
        },
        {
            label: (
                <a onClick={() => logOut()}>
                    Wyloguj się
                </a>
            ),
            key: '6',
        }
    ]

    return (
        <>
            <div className={'header-wrapper ' + `${isHome ? 'home-page' : 'pages'}`}>
                <div className="topbar-container">
                    <Row className="container-inner" justify="space-between">
                        <Col className="gutter-row" xs={10} span={12}>
                            <p className="top-text mobile-hide">Witaj w Obrabiarka.pl</p>
                            <div className="logo-wrapper desktop-hide">
                                <Link
                                    to={HOME_ROUTE}
                                    title="Obrabiarka.pl – giełda maszyn CNC"
                                >
                                    <img
                                        width="100%"
                                        style={{maxWidth: 200}}
                                        src={template.logo.data.attributes.url}
                                        alt="Obrabiarka.pl – giełda maszyn CNC"
                                    />
                                </Link>
                            </div>
                        </Col>
                        <Col className="gutter-row header-icons-custom" xs={14} span={12}>
                            <div className="icons">
                                <div className="icon-wrapper">
                                    <Link to={user.isAuth ? ACCOUNT_ROUTE_FAVOURITE : REGISTRATION_ROUTE}>
                                        <Space>
                                            <img src={favIcon} alt=""/>
                                            <span className="mobile-hide">Obserwowane</span>
                                        </Space>
                                    </Link>
                                </div>
                                {user.isAuth ?
                                    <>
                                        <div className="icon-wrapper">
                                            <Dropdown
                                                //overlay={menu}
                                                menu={{items}}
                                            >
                                                <a onClick={(e) => e.preventDefault()}>
                                                    <Space>
                                                        <img src={accIcon} alt="auth icon"/>
                                                        <span
                                                            className='text-link-color mobile-hide'>{user.user.fullName}</span>
                                                    </Space>
                                                </a>
                                            </Dropdown>
                                        </div>
                                        <div className="icon-wrapper">
                                            <Link to={ACCOUNT_ROUTE_PACKAGES}>
                                                <Space>
                                                    <img src={coinsIcon} alt=""/>
                                                    <span>{user.user.amount} zł</span>
                                                </Space>
                                            </Link>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="icon-wrapper">
                                            <Link to={REGISTRATION_ROUTE} rel="nofollow">
                                                <Space>
                                                    <img src={registerIcon} alt="no-auth icon"/>
                                                    <span className="mobile-hide">Załóż konto</span>
                                                </Space>
                                            </Link>
                                        </div>
                                        <div className="icon-wrapper">
                                            <Link to={LOGIN_ROUTE} rel="nofollow">
                                                <Space>
                                                    <img src={loginIcon} alt="no-auth icon"/>
                                                    <span className="mobile-hide">Zaloguj się</span>
                                                </Space>
                                            </Link>
                                        </div>
                                    </>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="header-container">
                    {window.innerWidth > 720 ?
                        <>
                            <Row className="container-inner header-container-middle" justify="space-between"
                                 align="middle">
                                <Col className="gutter-row" xs={0} lg={6}>
                                    <div className="logo-wrapper mobile-hide">
                                        <Link
                                            to={HOME_ROUTE}
                                            title="Obrabiarka.pl – giełda maszyn CNC"
                                        >
                                            <img
                                                width="100%"
                                                src={template.logo.data.attributes.url}
                                                alt="Obrabiarka.pl – giełda maszyn CNC"
                                            />
                                        </Link>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} lg={8}>
                                    <div className="search-wrapper">
                                        <Search
                                            placeholder="Jakiej maszyny szukasz.."
                                            name="query"
                                            onSearch={() => handleSubmit(query)}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={0} lg={6}>
                                    <Space className='btn-wrapper-right btn-ad mobile-hide'>
                                        <Link to={user.isAuth ? ACCOUNT_ROUTE_ADD_PRODUCT : ACCOUNT_ROUTE}>
                                            <Button style={{display: "block"}}
                                                    type="primary">
                                                Dodaj ogłoszenie
                                            </Button>
                                        </Link>
                                    </Space>
                                </Col>
                            </Row>
                            <div className="nav-container">
                                <Row style={{paddingRight: 0}} className="container-inner custom-row">
                                    <Col className="gutter-row category-list-wrapper" span={8} lg={6} xl={5}>
                                        <div className="category-wrapper">
                                            {isHome ?
                                                <>
                                                    <Space className='cat-list'>
                                                        <img src={listIcon} alt="list" width="100%"/>
                                                        <p>Wszystkie kategorie</p>
                                                    </Space>
                                                    <Menu
                                                        mode="vertical"
                                                        items={itemsMenu}
                                                    />
                                                    <Link className="cat-all" to={SEARCH_RESULT_ROUTE}>
                                                        Wszystkie kategorie
                                                    </Link>
                                                </>
                                                :
                                                <>
                                                    <button
                                                        onClick={() => setOpenDrawerCategory(true)}
                                                        className="button-all-cat"
                                                    >
                                                        <Space className='cat-list'>
                                                            <img src={listIcon} alt="" width="100%"/>
                                                            <p>Wszystkie kategorie</p>
                                                        </Space>
                                                    </button>
                                                    <Drawer
                                                        open={openDrawerCategory}
                                                        //visible={openDrawerCategory}
                                                        title="Wszystkie kategorie"
                                                        placement="left"
                                                        onClose={() => setOpenDrawerCategory(false)}

                                                    >
                                                        <Menu
                                                            mode="inline"
                                                            items={itemsMenu}
                                                        />
                                                    </Drawer>
                                                </>
                                            }
                                        </div>
                                    </Col>
                                    <Col className="gutter-row link-list-wrapper" span={16} lg={18} xl={19}>
                                        <nav className="link-container">
                                            <div className='links'>
                                                <a href={ABPLANALP_SHOP_ROUTE}>Sklep z narzędziami</a>
                                                <Link to={BLOG_ROUTE}>Blog</Link>
                                                <Link to={PRICES_ROUTE}>Cennik</Link>
                                                <Link to={CONTACT_ROUTE}>Kontakt</Link>
                                            </div>
                                        </nav>
                                    </Col>
                                </Row>
                            </div>
                        </>
                        :
                        <Affix offsetTop={0}>
                            <Row className="container-inner mobile" justify="space-between" align="middle">
                                <Col className="gutter-row" span={5}>
                                    <button
                                        className="mobile-ham"
                                        type="primary"
                                        onClick={() => setOpenDrawer(true)}
                                    >
                                        <img src={listIcon} alt="list" width="100%"/>
                                    </button>
                                    <Drawer
                                        open={openDrawer}
                                        //visible={openDrawer}
                                        title="Menu"
                                        placement="right"
                                        onClose={() => setOpenDrawer(false)}

                                    >
                                        <Menu
                                            mode="inline"
                                            items={itemsMenu}
                                        />
                                        <div className='links'>
                                            <a
                                                onClick={() => setOpenDrawer(false)}
                                                href={ABPLANALP_SHOP_ROUTE}>
                                                Sklep z narzędziami
                                            </a>
                                            <Link
                                                onClick={() => setOpenDrawer(false)}
                                                to={BLOG_ROUTE}>
                                                Blog
                                            </Link>
                                            <Link
                                                onClick={() => setOpenDrawer(false)}
                                                to={PRICES_ROUTE}>
                                                Cennik
                                            </Link>
                                            <Link
                                                onClick={() => setOpenDrawer(false)}
                                                to={CONTACT_ROUTE}>
                                                Kontakt
                                            </Link>
                                        </div>

                                    </Drawer>
                                </Col>
                                <Col className="gutter-row" span={19}>
                                    <div className="search-wrapper">
                                        <Search
                                            placeholder="Jakiej maszyny szukasz.."
                                            name="query"
                                            onSearch={() => handleSubmit(query)}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Affix>
                    }
                </div>
            </div>
        </>
    );
});

export default Navbar;