import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Button, Checkbox, Col, Form, Input, message, Row, Space} from "antd";

import {sendContactForm} from "../../http/globalAPI";
import {fetchContactInfo} from "../../http/pages/contactAPI";

import './style.less'


const Contact = () => {
    const [formDataContact, setFormDataContact] = useState({})
    const [htmlContact, setHTMLContact] = useState({__html: ""})
    const [form] = Form.useForm()

    useEffect(() => {
        fetchContactInfo().then(data => data.attributes.contact_info ? setHTMLContact({__html: data.attributes.contact_info}) : '')
    }, [])
    const click = async () => {
        try {
            let data = await sendContactForm(formDataContact).then(() => form.resetFields())
            message.success("Wiadomość wysłana!")
        } catch (e) {
            console.log("err", e.response.data.error)
        }
    }
    const handleChange = (e) => {
        const {name, value, checked} = e.target
        if (e.target.type === "checkbox") {
            setFormDataContact({...formDataContact, [name]: checked})
        } else {
            setFormDataContact({...formDataContact, [name]: value})
        }
    }
    const validateMessages = {
        required: 'Pole ${label} jest wymagane!',
        types: {
            email: 'Format ${label} jest nieprawidłowy!'
        }
    };
    return (
        <div className="page-wrapper contact-wrapper">
            <Helmet>
                <title>obrabiarka.pl - Skontaktuj się z Nami</title>
                <meta name="description" content="Ogłoszenia - maszyny i narzędzia CNC"/>
            </Helmet>
            <h1 className="title-main">Kontakt</h1>
            <Row gutter={[32, 32]} className="contact-mobile container-white">

                <Col span={24} lg={12}>
                    <div
                        className="contact-info-wrapper"
                        dangerouslySetInnerHTML={htmlContact}/>
                </Col>
                <Col span={24} lg={12} className="contact-form-wrapper">
                    <h3 className="contact-title">Wyślij do nas wiadomość</h3>
                    <Form
                        form={form}
                        validateMessages={validateMessages}
                        name=""
                        layout="vertical"
                        onFinish={click}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="name"
                            label="Imię i nazwisko"
                            rules={[{required: true}]}
                        >
                            <Input
                                placeholder='Imię i nazwisko'
                                name="name"
                                onChange={e => handleChange(e)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="company"
                            label="Nazwa firmy"
                            rules={[{required: true}]}
                        >
                            <Input
                                placeholder='Nazwa firmy'
                                name="company"
                                onChange={e => handleChange(e)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="E-mail"
                            rules={[
                                {type: 'email'},
                                {required: true},
                            ]}
                        >
                            <Input
                                placeholder='E-mail'
                                name="email"
                                onChange={e => handleChange(e)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="message"
                            label="Wiadomość"
                            rules={[{required: true}]}
                        >
                            <Input.TextArea
                                onChange={(e) => handleChange(e)}
                                placeholder="Wiadomość"
                                name="message"
                                showCount
                                maxLength={100}
                            />
                        </Form.Item>
                        <Form.Item
                            name="agreement"
                            valuePropName="checked"
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value ?
                                            Promise.resolve()
                                            :
                                            Promise.reject(new Error('Pole jest wymagane.'))
                                }
                            ]}
                        >
                            <Checkbox
                                name="agreement"
                                onChange={(e) => handleChange(e)}
                            >Potwierdzam, że zapoznałem się z
                                <a href="#"> polityką prywatności </a>
                                serwisu internetowego</Checkbox>
                        </Form.Item>
                        <Space className='btn-wrapper'>
                            <Button style={{display: "block"}}
                                    type="primary"
                                    htmlType="submit"
                            >
                                Wyślij wiadomość
                            </Button>
                        </Space>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default Contact;