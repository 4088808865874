import React, {useContext, useEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Spin} from "antd";

import {Context} from "../../index";
import {
    ACCOUNT_ROUTE, ACCOUNT_ROUTE_ADD_PRODUCT, ACCOUNT_ROUTE_FAVOURITE,
    ACCOUNT_ROUTE_MY_PRODUCTS, ACCOUNT_ROUTE_PACKAGES, MAIN_PAYMENT_ROUTE
} from "../../utils/consts";
import {fetchPromoPricing, fetchUserProducts} from "../../http/productAdAPI";
import InfoAccount from "./InfoAccount";
import ProductList from "./ProductList";
import AddProduct from "./AddProduct/AddProduct";
import UpdateProduct from "./AddProduct/UpdateProduct";
import Favorites from "./Favorites";
import BuyPackages from "./BuyPackages";
import PaymentStatus from "./Payment/PaymentStatus";

import './style.less'


const Account = observer((props) => {
    const {user, global} = useContext(Context)
    const [loading, setLoading] = useState(true)
    const [promoPrices, setPromoPrices] = useState({})
    const {pathname} = useLocation()
    const isMyAcc = pathname === ACCOUNT_ROUTE
    const isMyListProductAd = pathname === ACCOUNT_ROUTE_MY_PRODUCTS
    const isAddProductAd = pathname === ACCOUNT_ROUTE_ADD_PRODUCT
    const isProductFav = pathname === ACCOUNT_ROUTE_FAVOURITE
    const isBuyPackages = pathname === ACCOUNT_ROUTE_PACKAGES
    const isPaymentStatus = pathname === MAIN_PAYMENT_ROUTE
    const sessionID = `${new Date().getTime()}-${user.user.id}`


    useEffect(() => {
        fetchUserProducts().then(data => {
            return user.setUserProductsAd(data.products)
        })
            .finally(() => setLoading(false))
        fetchPromoPricing().then((data) => {
            setPromoPrices(data[0].attributes)
        })
    }, [user.productsAd, user.userFav, user.userOrders])

    useEffect(() => {
        const productsFav = JSON.parse(
            localStorage.getItem('fav')
        );
        if (productsFav) {
            user.setUserFav(productsFav);
        }
    }, []);

    if (loading) {
        return <Spin size="large" className="spin-main"/>
    }
    return (
        <div className="page-wrapper">
            <h1 className="title-main">
                {isMyAcc ? "Moje Konto"
                    : isMyListProductAd ? "Moje ogłoszenia"
                        : isAddProductAd ? "Dodaj ogłoszenie"
                            : isProductFav ? "Obserwowane"
                                : isBuyPackages ? "Doładuj konto"
                                    : "Edytuj ogłoszenie"
                }
            </h1>
            <div className="acc-nav-wrapper">
                <NavLink className='link' to={ACCOUNT_ROUTE}>Twoje Konto</NavLink>
                <NavLink className='link' to={ACCOUNT_ROUTE_MY_PRODUCTS}>Moje ogłoszenia</NavLink>
                <NavLink className='link' to={ACCOUNT_ROUTE_ADD_PRODUCT}>Dodaj ogłoszenie</NavLink>
                <NavLink className='link' to={ACCOUNT_ROUTE_FAVOURITE}>Obserwowane</NavLink>
                <NavLink className='link' to={ACCOUNT_ROUTE_PACKAGES}>Doładuj konto</NavLink>
            </div>
            <div className="acc-content-wrapper">
                {isMyAcc ?
                    <InfoAccount user={user} voivodeships={global.voivodeships}/>
                    : isMyListProductAd ?
                        <ProductList productsAd={user}/>
                        : isAddProductAd ?
                            <AddProduct
                                user={user}
                                categories={global.categories}
                                voivodeships={global.voivodeships}
                                promoPrices={promoPrices}
                            />
                            : isProductFav ?
                                <Favorites
                                    productsFav={user.userFav}
                                />
                                : isBuyPackages ?
                                    <BuyPackages
                                        user={user}
                                        sessionID={sessionID}
                                        voivodeships={global.voivodeships}
                                    />
                                    : isPaymentStatus ?
                                        <PaymentStatus
                                            user={user}
                                            sessionID={sessionID}
                                        />
                                        : <UpdateProduct
                                            user={user}
                                            promoPrices={promoPrices}
                                        />
                }
            </div>
        </div>
    );
});

export default Account;