import {makeAutoObservable, toJS} from "mobx";


export default class GlobalStore {
    constructor() {
        this._categories = []
        this._encCatategories = {}
        this._voivodeships = []
        makeAutoObservable(this)
    }

    setCategories(categories) {
        this._categories = categories
    }

    setEncCategories(categories) {
        let encListCategories = {}
        categories.map((cat) => {
                let subCat = cat.attributes.subCategories.data
                 encListCategories = {...encListCategories, [cat.attributes.slug]: cat.attributes.name}
                return subCat.length ?
                    subCat.map(sub => {
                        encListCategories = {
                            ...encListCategories,
                            [`${cat.attributes.slug}-${sub.attributes.slug}`]:
                                `${cat.attributes.name} > ${sub.attributes.name}`
                        }
                    })
                    : {}
            })
        this._encCatategories = encListCategories
    }

    setVoivodeships(voivodeships) {
        this._voivodeships = voivodeships
    }

    get categories() {
        return toJS(this._categories)
    }

    get encCategories() {
        return toJS(this._encCatategories)
    }

    get voivodeships() {
        return toJS(this._voivodeships)
    }
}