import {$authHost, $host} from "./index";


export const createProductAd = async () => {
    const {data} = await $authHost.post('/products')
    return data.data
}

export const fetchProducts = async () => {
    const {data} = await $host.get('/products?populate=*&randomSort=true&pagination[limit]=70')
    return data.data
}

export const fetchCategories = async () => {
    const {data} = await $host.get('/categories?populate=*&sort=order')
    return data.data
}

export const fetchProductOne = async (slug) => {
    const {data} = await $host.get(`slugify/slugs/product/${slug}?populate=*`)
    return data.data
}

export const uploadImageUserProductAd = async (formData) => {
    const {data} = await $authHost.post('/upload', formData)
    return data
}

export const removeUserProductsImage = async (id) => {
    const {data} = await $authHost.delete(`upload/files/${id}`)
    return data.data
}

export const createUserProductAd = async (userProductAd) => {
    const {data} = await $authHost.post('/products?populate=*', {data:{...userProductAd}})
    return data
}
export const deleteUserProductAd = async (id, userProductAd) => {
    const {data} = await $authHost.put(`/products/${id}?populate=*`, {data:{...userProductAd}})
    return data
}
// export const deleteUserProductAd = async (id) => {
//     const {data} = await $authHost.delete(`/products/${id}`)
//     return data
// }

export const getSingleUserProductAd = async (id) => {
    const {data} = await $authHost.get(`/products/${id}?populate=*` )
    return data
}

export const updateUserProductAd = async (id, userProductAd) => {
    const {data} = await $authHost.put(`/products/${id}?populate=*`, {data:{...userProductAd}})
    return data
}

export const fetchUserProducts = async () => {
    const {data} = await $authHost.get('/users/me?populate=products.category&populate=products.subCategory&populate=products.gallery')
    return data
}

export const fetchUserProductOne = async (id) => {
    const {data} = await $authHost.get(`products/${id}?populate=*`)
    return data.data
}

export const fetchPromoPricing = async () => {
    const {data} = await $authHost.get(`/prices`)
    return data.data
}