import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";

import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import {fetchTemplate} from "../http/globalAPI";


const Layout = () => {
    const [template, setTemplate] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchTemplate().then((data) => data.attributes ? setTemplate(data.attributes) : '')
            .finally(() => setLoading(false))
    }, [])

    if (loading) {
        return;
    }
    return (
        <>
            <header>
                <div className="container">
                    <Navbar template={template}/>
                </div>
            </header>
            <div className='main-content'>
                <div className="container">
                    <Outlet/>
                </div>
            </div>
            <footer className="footer-wrapper">
                <div className="container">
                    <Footer template={template}/>
                </div>
            </footer>

        </>
    );
};

export default Layout;